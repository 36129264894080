<div class="dialog-content">
    <div class="token-configuration">
        <app-token-configuration [dataForm]="dataForm" [hide-type]="hideType" [contracts]="contracts"
                                [readonly]="readonly"></app-token-configuration>
    </div>
    <div class="dialog-footer">
        <button (click)="this.dialogRef.close()" class="button secondary" label="Close" pButton
                type="button"></button>
        <button (click)="this.dialogRef.close(dataForm)" [disabled]="dataForm.invalid" [label]="currentTokenId ? 'Save' : 'Create'" class="button" pButton
                type="button"></button>
    </div>
</div>