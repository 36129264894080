<div class="content" [hidden]="!isActive">
    <div>
        <h2>{{ title }}</h2>
        <h3>{{ description }}</h3>
        <form [formGroup]="roleForm" (ngSubmit)="onSubmit()">
            <div class="roles-form">

                <div *ngIf="!isGroup" class="role-value">
                    <label class="form-label" htmlFor="role">Role</label>
                    <p-dropdown
                        #operationDropdown
                        [options]="roles"
                        formControlName="roleOrGroup"
                        id="role"
                        placeholder="Not selected"
                    >
                    </p-dropdown>
                </div>

                <mat-radio-group *ngIf="isGroup" aria-label="Select an option" [value]="type">
                    <div>
                        <div class="group-name">
                            <mat-radio-button value="new" color="primary" (change)="onChange($event)">Create new group
                            </mat-radio-button>
                        </div>
                        <div class="group-value" [attr.disabled]="type!=='new'">
                            <mat-form-field appearance="outline">
                                <mat-label>Group Template</mat-label>
                                <mat-select formControlName="roleOrGroup">
                                    <mat-option *ngFor="let group of groups" [value]="group">{{group}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field *ngIf="ifPrivateGroup()" appearance="outline">
                                <mat-label>Group Label</mat-label>
                                <input matInput formControlName="groupLabel">
                            </mat-form-field>
                        </div>
                    </div>
                    <div>
                        <div class="group-name">
                            <mat-radio-button value="invite" color="primary" (change)="onChange($event)">
                                Accept invitation
                            </mat-radio-button>
                        </div>
                        <div class="group-value" [attr.disabled]="type!=='invite'">
                            <div *ngIf="policyName" style="margin-bottom: 15px;color: #707070">
                                <span>Policy: </span>
                                <span>{{policyName}}</span>
                            </div>
                            <div *ngIf="groupName" style="margin-bottom: 15px;color: #707070">
                                <span>Group: </span>
                                <span>{{groupName}}</span>
                            </div>
                            <div *ngIf="groupLabel" style="margin-bottom: 15px;color: #707070">
                                <span>Label: </span>
                                <span>{{groupLabel}}</span>
                            </div>
                            <div *ngIf="inviteRole" style="margin-bottom: 15px;color: #707070">
                                <span>Role: </span>
                                <span>{{inviteRole}}</span>
                            </div>
                            <mat-form-field appearance="outline">
                                <mat-label>Invite</mat-label>
                                <input matInput formControlName="invitation" (ngModelChange)="onParse($event)">
                            </mat-form-field>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </form>
    </div>
</div>

<div class="progress-footer" id="block-progress-footer">
    <div>
        <!-- <button pButton type="button" class="button" label="Previous" [disabled]="true"></button> -->
    </div>
    <div>
        <!-- <button pButton type="button" class="p-button-outlined" label="Save Changes" style="margin-right: 16px;"></button> -->
        <button (click)="onSubmit()"
            [disabled]="!roleForm.valid"
            class="p-button"
            form="roles-form"
            label="Next"
            pButton
            type="submit"
        ></button>
    </div>
</div>

<ng-template #preloader>
    <div class="preloader-image"></div>
</ng-template>

<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>
