<div class="dialog-body">
    <form [formGroup]="forgotPasswordFormGroup">
        <div class="form-input-container">
            <label class="p-field-label" for="username">Username</label>
            <input class="p-field-input" formControlName="username" id="username" pInputText placeholder="Username"
                   type="text"/>
        </div>
    </form>
</div>
<div class="dialog-footer">
    <div class="action-buttons">
        <div class="cancel-button">
            <button (click)="onNoClick()"
                    class="button secondary"
                    label="Cancel"
                    pButton
            ></button>
        </div>
        <div>
            <button
                    (click)="onSubmit()"
                    [disabled]="!forgotPasswordFormGroup.controls['username'].value" class="button" label="Reset Password" pButton></button>
        </div>
    </div>
</div>
