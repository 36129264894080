<div class="annotation-block">
    <div class="header">
        <div class="logotype">
        </div>
        <h1>Clean Development Mechanism</h1>
        <h6>The CDM enables projects in developing nations to earn tradable CERs. These credits <br> represent one tonne
            of CO2 and help industrialized countries meet Kyoto targets.</h6>

        <div class="counter-container">
            <div class="item">
                <h3>{{ counters.registeredProjects }}</h3>
                <h6>Registered projects</h6>
            </div>
            <div class="bounder"></div>
            <div class="item">
                <h3>{{ counters.registeredPoAs }}</h3>
                <h6>Registered PoAs</h6>
            </div>
            <div class="bounder"></div>
            <div class="item">
                <h3>{{ counters.cERsIssuedForProgrammeOfActivities }}</h3>
                <h6>CERs Issued for Programme of Activities</h6>
            </div>
            <div class="bounder"></div>
            <div class="item">
                <h3>{{ counters.cERsIssuedForProjectActivities }}</h3>
                <h6>CERs Issued for Project Activities</h6>
            </div>
        </div>
    </div>
</div>
<app-projects-overview></app-projects-overview>