<div class="content">
    <div class="content-trust-chain">
        <div class="trust-chain-label">Trust Chain</div>
        <form
            (ngSubmit)="updateFilter()"
            *ngIf="!mainDocuments"
            [formGroup]="searchForm"
            class="filters"
        >
            <mat-form-field class="search-input" appearance="outline">
                <mat-label class="hash-label">HASH/ID</mat-label>
                <input
                    formControlName="value"
                    matInput
                    placeholder="HASH/ID"
                    type="text"
                />
                <button
                    [disabled]="!searchForm.valid"
                    mat-icon-button
                    matSuffix
                    type="submit"
                >
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <div class="not-exist" *ngIf="!mainDocuments && this.hash">
            Can't find document with HASH: {{ this.hash }}
        </div>

        <div *ngIf="mainDocuments" class="display-chain">
            <div
                *ngFor="let item of mainDocuments"
                class="verifiable-presentation"
            >
                <div *ngIf="item.vpDocument" class="header card-block">
                    <div class="card-info">
                        <h3>Verifiable Presentation</h3>
                        <mat-icon>check_circle</mat-icon>
                        <span class="vertical-devider"></span>
                        <button (click)="openVPDocument(item.vpDocument)" class="p-button-outlined" icon="pi pi-file" label="VP File"
                                pButton
                        ></button>
                    </div>
                    <div class="hash-info">
                        <button (click)="onBackClick()" class="p-button-outlined" icon="pi pi-chevron-left" label=""
                                pButton
                        ></button>
                        <div>
                            <p class="label">HASH</p>
                            <p>{{ item.vpDocument.hash }}</p>
                        </div>
                    </div>
                </div>

                <div *ngIf="item.vcDocument" class="header card-block">
                    <div class="hash-info">
                        <button (click)="onBackClick()" class="p-button-outlined" icon="pi pi-chevron-left" label=""
                                pButton
                        ></button>
                        <button (click)="openVCDocument(item.vcDocument)" class="p-button-outlined" icon="pi pi-file" label="File"
                                pButton
                        ></button>
                        <div>
                            <p class="label">HASH</p>
                            <p>{{ item.vcDocument.hash }}</p>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="item.mintDocument"
                    [attr.vp-section-offset]="mainDocuments.length > 1"
                    class="vp-section"
                >
                    <div class="vp-section-header">
                        <h3>Token & Issuer</h3>
                        <span class="vertical-devider"></span>
                        <button (click)="openJsonDocument(item.mintDocument)" class="p-button-outlined" icon="pi pi-file" label="VC File"
                                pButton
                        ></button>
                    </div>
                    <div class="card-block vp-block">
                        <div class="vp-field">
                            <div class="vp-field-label">Token</div>
                            <div [pTooltip]="item.mintDocument.tokenId" class="vp-field-value">
                                {{ item.mintDocument.tokenId }}
                            </div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Receipt Name</div>
                            <div
                                [pTooltip]="item.mintDocument.username"
                                [title]="item.mintDocument.issuer"
                                class="vp-field-value"
                            >
                                {{ item.mintDocument.username }}
                            </div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Token amount</div>
                            <div [pTooltip]="item.mintDocument.amount || ''" class="vp-field-value">
                                {{ item.mintDocument.amount }} / {{ item.mintDocument.expected }} minted
                            </div>
                        </div>
                        <div class="vp-field" *ngIf="item.mintDocument.wasTransferNeeded">
                            <div class="vp-field-label">Token transfer</div>
                            <div [pTooltip]="item.mintDocument.transferAmount || ''" class="vp-field-value">
                                {{ item.mintDocument.transferAmount }} / {{ item.mintDocument.expected }} transferred
                            </div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Mint date</div>
                            <div [pTooltip]="item.mintDocument.date" class="vp-field-value">
                                {{ item.mintDocument.date }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="(item.primaryImpacts && item.primaryImpacts.length) || (item.secondaryImpacts && item.secondaryImpacts.length)"
                    [attr.vp-section-offset]="mainDocuments.length > 1"
                    class="vp-section impact-section offset"
                >
                    <div *ngIf="item.primaryImpacts && item.primaryImpacts.length">
                        <div class="vp-section-header">
                            <h3>Primary Impacts</h3>
                        </div>
                        <div class="vp-table">
                            <div class="cards">
                                <div class="cards-container">
                                    <div
                                        *ngFor="let item of item.primaryImpacts"
                                        [attr.main]="true"
                                        class="card-item"
                                    >
                                        <div class="card-title">
                                            <div class="card-icon">
                                                <mat-icon>workspace_premium</mat-icon>
                                            </div>
                                            <span>{{ item.label }}</span>
                                        </div>
                                        <div class="card-right">
                                            <a (click)="openVCDocument(item,item.document.document)"
                                                *ngIf="item.document"
                                                class="open-vc"
                                            >VC File</a>
                                        </div>
                                        <div class="card-body">
                                            <div
                                                style="
                                                    border-bottom: 1px solid
                                                        rgb(112 112 112 / 64%);
                                                    margin-bottom: 10px;
                                                    padding-bottom: 10px;
                                                ">
                                                <span>{{item.description}}</span>
                                            </div>
                                            <div>
                                                <span>{{ item.amount }}</span>
                                                <span> {{ item.unit }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="item.secondaryImpacts && item.secondaryImpacts.length"
                        [attr.offset]="
                            !!(
                                item.primaryImpacts &&
                                item.primaryImpacts.length
                            )
                        "
                    >
                        <div class="vp-section-header">
                            <h3>Secondary Impacts</h3>
                        </div>
                        <div class="vp-table">
                            <div class="cards">
                                <div class="cards-container">
                                    <div *ngFor="let item of item.secondaryImpacts" [attr.main]="false" class="card-item">
                                        <div class="card-title">
                                            <div class="card-icon">
                                                <mat-icon>workspace_premium</mat-icon>
                                            </div>
                                            <span>{{ item.label }}</span>
                                        </div>
                                        <div class="card-right">
                                            <a (click)="openVCDocument(item,item.document.document)" *ngIf="item.document" class="open-vc">VC File</a>
                                        </div>
                                        <div class="card-body">
                                            <div style="
                                                    border-bottom: 1px solid
                                                        rgb(112 112 112 / 64%);
                                                    margin-bottom: 10px;
                                                    padding-bottom: 10px;
                                                ">
                                                <span>{{item.description}}</span>
                                            </div>
                                            <div>
                                                <span>{{ item.amount }}</span>
                                                <span> {{ item.unit }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    [attr.vp-section-offset]="mainDocuments.length > 1"
                    class="vp-section"
                >
                    <div class="vp-section-header">
                        This Carbon Offset Claim has met all the requirements as
                        issued in the policy secured to this token.
                    </div>
                    <div class="vp-table">
                        <div *ngIf="item.vpDocument" class="vp-signature">
                            <div class="vp-field-label">
                                Verified Signature:
                            </div>
                            <div class="vp-field-value">
                                {{ item.vpDocument.document.document?.proof?.jws}}
                            </div>
                        </div>
                        <div *ngIf="item.vcDocument" class="vp-signature">
                            <div class="vp-field-label">
                                Verified Signature:
                            </div>
                            <div class="vp-field-value">
                                {{item.vcDocument.document.document?.proof?.jws}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="policyDocument" class="verifiable-presentation">
                <div class="vp-section">
                    <div class="vp-section-header">
                        <h3>Policy Overview</h3>
                        <span class="vertical-devider"></span>
                        <button (click)="openVCDocument(policyDocument)" class="p-button-outlined" icon="pi pi-file" label="VC File"
                                pButton
                        ></button>
                    </div>
                    <div class="card-block vp-block">
                        <div class="vp-field">
                            <div class="vp-field-label">Policy Name</div>
                            <div [pTooltip]="policyDocument.name" class="vp-field-value">
                                {{ policyDocument.name }}
                            </div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Description</div>
                            <div [pTooltip]="policyDocument.description" class="vp-field-value">
                                {{ policyDocument.description }}
                            </div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Version</div>
                            <div [pTooltip]="policyDocument.version" class="vp-field-value">
                                {{ policyDocument.version }}
                            </div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Issuer Name</div>
                            <div
                                [pTooltip]="policyDocument.username"
                                [title]="policyDocument.issuer"
                                class="vp-field-value"
                            >
                                {{ policyDocument.username }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div (click)="onScrollButtonPress(scrollContainer, -150)" class="scroll-right">
                    <mat-icon>chevron_left</mat-icon>
                </div>
                <div
                    #scrollContainer
                    (wheel)="onWheel($event)"
                    class="chain-container"
                >
                    <ng-container *ngFor="let item of documents">
                        <div class="multiple-documents-container">
                            <ng-container
                                *ngIf="
                                    !item.notFoundDocuments;
                                    else documentsNotFound
                                ">
                                <div
                                    (click)="onMultipleDocumentClick(document, item)"
                                    *ngFor="let document of item.document"
                                    style="display: inline-block;"
                                >
                                    <div [ngStyle]="{
                                            opacity:
                                                document.index === 0 &&
                                                item.dynamicFilters.length &&
                                                item.document.length > 1 ? 1 : 0,
                                            transform:
                                                document.index === 0 &&
                                                item.dynamicFilters.length &&
                                                item.document.length > 1
                                                    ? 'scale(1, 1) translate(0, 0)'
                                                    : 'scale(1, 0) translate(20px, 0)'
                                        }"
                                        class="item-relationships-delimiter"
                                    ></div>
                                    <mat-icon
                                        *ngIf="
                                        document.index === 0 &&
                                        item.dynamicFilters.length &&
                                        item.document.length > 1"
                                        class="item-relationships-icon material-icons-outlined">
                                        account_tree
                                    </mat-icon>
                                    <div
                                        [ngClass]="{
                                            'active-multiple-document':
                                                document.index === 0 &&
                                                item.document.length > 1,
                                            'second-multiple-document':
                                                document.index === 1,
                                            'third-multiple-document':
                                                document.index === 2,
                                            'hide-multiple-document':
                                                item.multiple &&
                                                (document.index === undefined ||
                                                    document.index === null),
                                            'single-multiple-document':
                                                item.document.length === 1
                                        }"
                                        class="chain-item item-type-{{item.type}}"
                                    >
                                        <div class="chain-title">
                                            <ng-container
                                                *ngIf="item.icon"
                                                [ngSwitch]="item.iconType"
                                            >
                                                <mat-icon *ngSwitchCase="'common'">{{ item.icon }}</mat-icon>
                                                <img
                                                    *ngSwitchCase="'custom'"
                                                    [src]="item.icon"
                                                    class="item-image"
                                                />
                                            </ng-container>
                                            {{ item.title }}
                                        </div>
                                        <div
                                            *ngIf="
                                                document.document &&
                                                document.document.comment
                                            "
                                            class="revoke-container"
                                        >
                                            <mat-icon class="revoke-icon">warning</mat-icon>
                                            <b>Revoked with reason: "{{document.document.comment}}"</b>
                                        </div>
                                        <div class="chain-id">
                                            {{ item.description }}
                                        </div>
                                        <div
                                            *ngIf="document.username"
                                            class="parties"
                                        >
                                            <div class="parties-label">
                                                Parties:
                                            </div>
                                            <div class="parties-value">
                                                <div class="partie">
                                                    {{ document.username }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="nested-documents">
                                            <ng-container *ngFor="let link of item.documents">
                                                <a (click)="openVCDocument(link)"
                                                    [title]="link.description"
                                                    class="nested-documents-value"
                                                >
                                                    {{ link.title }}
                                                </a>
                                            </ng-container>
                                        </div>
                                        <div
                                            *ngIf="document.document"
                                            class="chain-document"
                                        >
                                            <a (click)="openVCDocument(item,document.document.document)">VC File</a>
                                        </div>
                                        <div
                                            *ngIf="
                                                item.activeDocumentIndex &&
                                                item.document &&
                                                item.document.length > 1
                                            "
                                            class="multiple-documents-count"
                                        >
                                            <mat-icon (click)="onPrevDocumentClick($event,item,document)">chevron_left</mat-icon>
                                            {{ item.activeDocumentIndex }} in
                                            {{ item.document.length }}
                                            <mat-icon (click)="onNextDocumentClick($event,item,document)">chevron_right</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #documentsNotFound>
                                <div
                                    *ngIf="
                                        item.notFoundDocuments;
                                        displayDocuments
                                    "
                                    style="display: inline-block;"
                                >
                                    <div class="chain-item empty-documents">
                                        <div class="chain-title">
                                            {{ item.title }}
                                        </div>
                                        <div class="empty-documents-description">
                                            <mat-icon>search</mat-icon>
                                            <div class="chain-id">
                                                Documents Not Found
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
                <div
                    (click)="onScrollButtonPress(scrollContainer, 150)"
                    class="scroll-right"
                >
                    <mat-icon>chevron_right</mat-icon>
                </div>
            </div>
            <div style="height: 64px;"></div>
        </div>
    </div>
</div>

<ng-template #preloader>
    <div class="preloader-image"></div>
</ng-template>

<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>
