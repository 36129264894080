<app-dialog-wrapper (cancel)="onNoClick()" [confirmVisible]="false" [loading]="loading" [title]="'Retire requests'"
    [length]="length" [pageSize]="pageSize" (page)="onPage($event)">

    <div *ngIf="requests?.length; else noRequests">
        <div *ngFor="let request of requests" class="request">
            <div class="tokens">
                <div class="token-card" *ngFor="let token of request.tokens">
                    <span class="token-card__token">
                        <mat-icon>abc</mat-icon>
                        <span>{{token.tokenSymbol}}</span>
                    </span>
                    <span class="token-card__token">
                        <mat-icon>generating_tokens</mat-icon>
                        <span>{{token.token}}</span>
                    </span>
                    <span class="token-card__count" *ngIf="token.count > 0">
                        <mat-icon>calculate</mat-icon>
                        <span>{{token.count | tokenCount: token.decimals }}</span>
                    </span>
                    <span class="token-card__serials" *ngIf="token.serials?.length > 0"
                        [title]="token.serials?.join(', ')">
                        <mat-icon>123</mat-icon>
                        <span>{{token.serials?.join(', ')}}</span>
                    </span>
                </div>
            </div>
            <div class="request__actions">
                <button (click)="unsetRetire(request.id)" mat-flat-button class="btn btn--reject">Cancel</button>
            </div>
        </div>
    </div>
</app-dialog-wrapper>
<ng-template #noRequests>
    <div class="no-requests">You have no retire requests</div>
</ng-template>