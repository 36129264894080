<h1 class="about-title">About Guardian</h1>
<div *ngIf="!isLoading; else spinner">
    <div class="about-container">
        <div class="about-grid-row">
            <div class="title-column about-grid-column">Version</div>
            <div class="value-column about-grid-column">{{about.version}}</div>
        </div>
    </div>
</div>

<ng-template #spinner>
    <mat-spinner class="loading"></mat-spinner>
</ng-template>
