<div [hidden]="!isExist" class="content-{{ type }}">
    <ng-container *ngIf="type == 'dialog'">
        <div class="create-button-container">
            <p-button (click)="onDialog()" class="btn-block-{{ buttonClass }}">
                {{ content }}
            </p-button>
        </div>
    </ng-container>
    <ng-container *ngIf="type == 'page'">
        <form
            (ngSubmit)="onSubmit('page')"
            *ngIf="schema"
            [formGroup]="dataForm"
            class="form-{{ type }}"
        >
            <h1 *ngIf="title" mat-dialog-title>{{ title }}</h1>

            <div *ngIf="restoreData" class="restore-data">
                <mat-icon>info</mat-icon>
                <b>There is some data to restore. You can restore latest values:</b>
                <button
                    (click)="onRestoreClick()"
                    aria-label="Restore Values"
                    mat-raised-button
                    type="button"
                >
                    Restore Values<mat-icon>restore</mat-icon>
                </button>
            </div>

            <div *ngIf="dryRun" class="restore-data">
                <mat-icon>info</mat-icon>
                <b>There is dry run mode. You can fill document with test data:</b>
                <button
                    (click)="onDryRun()"
                    aria-label="Fill Values"
                    mat-raised-button
                    type="button"
                >
                    Test<mat-icon>restore</mat-icon>
                </button>
            </div>

            <div class="schema-form">
                <app-schema-form
                    (cancelBtnEvent)="onCancelPage($event)"
                    (submitBtnEvent)="onSubmit('page')"
                    [formGroup]="dataForm"
                    [preset]="presetDocument"
                    [private-fields]="hideFields"
                    [readonly-fields]="presetReadonlyFields"
                    [schema]="schema"
                    [dryRun]="dryRun"
                    [policyId]="policyId"
                    submitText="Create"
                >
                </app-schema-form>
            </div>
        </form>
    </ng-container>
</div>

<ng-template #dialogTemplate let-data>
    <div class="dialog-template">
        <h1 class="title" mat-dialog-title>{{ dialogContent }}</h1>
        <div *ngIf="restoreData" class="restore-data">
            <mat-icon>info</mat-icon>
            <b>There is some data to restore. You can restore latest values:</b>
            <button
                (click)="onRestoreClick()"
                aria-label="Restore Values"
                mat-raised-button
                type="button"
            >
                Restore Values<mat-icon>restore</mat-icon>
            </button>
        </div>
	    <div *ngIf="dryRun" class="restore-data">
            <mat-icon>info</mat-icon>
            <b>There is dry run mode. You can fill document with test data:</b>
            <button
                (click)="onDryRun()"
                aria-label="Fill Values"
                mat-raised-button
                type="button"
            >
                Test<mat-icon>restore</mat-icon>
            </button>
        </div>

        <div *ngIf="schema" class="body-dialog-template" mat-dialog-content>
            <ng-template #preloader>
                <div class="preloader-image"></div>
            </ng-template>

            <div *ngIf="dialogLoading" class="loading">
                <div class="preloader-image preloader-image-l-size"></div>
            </div>
            <form (ngSubmit)="onSubmit('dialog')" [formGroup]="dataForm">
                <div class="form-{{ type }}" [attr.dry-run]="!!dryRun" [attr.restore]="!!restoreData">
                    <app-schema-form
                        (cancelBtnEvent)="handleCancelBtnEvent($event, this)"
                        (submitBtnEvent)="handleSubmitBtnEvent($event, this)"
                        [comesFromDialog]="true"
                        [formGroup]="dataForm"
                        [preset]="presetDocument"
                        [private-fields]="hideFields"
                        [readonly-fields]="presetReadonlyFields"
                        [dryRun]="dryRun"
                        [policyId]="policyId"
                        [schema]="schema"
                    >
                    </app-schema-form>
                </div>
                <div class="dialog-actions-container">
                    <div mat-dialog-actions style="display: flex;">
                        <div style="margin-left: auto;">
                            <p-button
                                (click)="onCancel()"
                                id="cancel-btn"
                                label="Cancel"
                                styleClass="p-button-outlined"
                                type="button"
                            >
                            </p-button>
                        </div>
                        <div style="margin-left: 12px; margin-right: 30px;">
                            <p-button
                                [disabled]="!dataForm.valid || loading || dialogLoading"
                                id="ok-btn"
                                label="Create"
                                type="submit"
                            >
                            </p-button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="!schema" mat-dialog-actions>
            <button (click)="onCancel()" mat-button type="button">
                Cancel
            </button>
        </div>
    </div>
</ng-template>

<ng-template #preloader>
    <div class="preloader-image"></div>
</ng-template>

<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>
