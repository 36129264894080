<div class="content">
    <div *ngIf="schema1 && schema2" class="list">

        <div class="total-info" [attr.total]="total">
            Schemas are <span>{{total}}%</span> the same.
        </div>

        <mat-accordion multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="header">Description</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="info-container">
                    <ng-container *ngTemplateOutlet="schemaInfo; context: {schema:schema1}"></ng-container>
                    <ng-container *ngTemplateOutlet="schemaInfo; context: {schema:schema2}"></ng-container>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <div class="header">Schema Fields</div>
                </mat-expansion-panel-header>

                <div class="merge-container">

                    <div class="legend" [attr.type-1]="type1" [attr.type-2]="type2" [attr.type-3]="type3"
                        [attr.type-4]="type4">
                        <div class="legend-item">
                            <div class="legend-icon legend-l1" (click)="type1=!type1"></div>
                            <div class="legend-text">
                                Fields are completely equal.
                            </div>
                        </div>
                        <div class="legend-item">
                            <div class="legend-icon legend-l2" (click)="type2=!type2"></div>
                            <div class="legend-text">
                                Sub-Schemas are partially equal.
                            </div>
                        </div>
                        <div class="legend-item">
                            <div class="legend-icon legend-l3" (click)="type3=!type3"></div>
                            <div class="legend-text">
                                Fields are partially equal.
                            </div>
                        </div>
                        <div class="legend-item">
                            <div class="legend-icon legend-l4" (click)="type4=!type4"></div>
                            <div class="legend-text">
                                Match not found.
                            </div>
                        </div>
                    </div>

                    <div *ngIf="type==='tree'" class="merge-tree" [attr.open]="panelOpenState" [attr.type-1]="type1"
                        [attr.type-2]="type2" [attr.type-3]="type3" [attr.type-4]="type4">

                        <div *ngFor="let item of report; let i=index" class="report-item report-item-number"
                            [attr.type]="item.type" [attr.rate]="item.total_rate" [attr.hidden]="item._hidden">
                            <div class="report-number">{{i+1}}</div>
                            <div class="report-collapse" [attr.collapse]="item._collapse" (click)="onCollapse(item)">
                                <mat-icon class="open-icon">remove</mat-icon>
                                <mat-icon class="collapse-icon">add</mat-icon>
                            </div>

                            <div *ngIf="item.left_name" [attr.offset]="item.lvl" [style.padding-left.px]="30*item.lvl"
                                class="left-tree">
                                <div class="field-tree" (click)="item._open = !item._open">
                                    <div class="field-index" [attr.index-rate]="item.index_rate">
                                        {{item.left_index}}
                                    </div>
                                    <div class="field-type">{{item.left_name}}</div>
                                </div>
                            </div>
                            <div *ngIf="!item.left_name && item.right_name" [attr.offset]="item.lvl"
                                [style.padding-left.px]="30*item.lvl" class="left-tree fantom-tree">
                                <div class="field-tree">
                                    <div class="field-index" [attr.index-rate]="item.index_rate">
                                        {{item.right_index}}
                                    </div>
                                    <div class="field-type">{{item.right_name}}</div>
                                </div>
                            </div>

                            <div class="middle-tree">
                                <div class="compare-btn" (click)="item._open = !item._open">
                                    <mat-icon *ngIf="!item._open">unfold_more</mat-icon>
                                    <mat-icon *ngIf="item._open">unfold_less</mat-icon>
                                </div>
                            </div>

                            <div *ngIf="item.right_name" [attr.offset]="item.lvl" [style.padding-left.px]="30*item.lvl"
                                class="right-tree">
                                <div class="field-tree" (click)="item._open = !item._open">
                                    <div class="field-index" [attr.index-rate]="item.index_rate">
                                        {{item.right_index}}
                                    </div>
                                    <div class="field-type">{{item.right_name}}</div>
                                </div>
                            </div>
                            <div *ngIf="!item.right_name && item.left_name" [attr.offset]="item.lvl"
                                [style.padding-left.px]="30*item.lvl" class="right-tree fantom-tree">
                                <div class="field-tree">
                                    <div class="field-index" [attr.index-rate]="item.index_rate">
                                        {{item.left_index}}
                                    </div>
                                    <div class="field-type">{{item.left_name}}</div>
                                </div>
                            </div>

                            <div class="left-compare-result compare-result" [attr.open]="item._open"
                                [style.padding-left.px]="30*item.lvl">
                                <ng-container *ngIf="item.left">
                                    <ng-container
                                        *ngTemplateOutlet="propInfo; context: { report:item, index:0 }"></ng-container>
                                </ng-container>
                            </div>
                            <div class="middle-compare-result compare-result" [attr.open]="item._open"></div>
                            <div class="right-compare-result compare-result" [attr.open]="item._open"
                                [style.padding-left.px]="30*item.lvl">
                                <ng-container *ngIf="item.right">
                                    <ng-container
                                        *ngTemplateOutlet="propInfo; context: { report:item, index:1 }"></ng-container>
                                </ng-container>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="type==='table'" class="merge-table" [attr.open]="panelOpenState">
                        <div class="merge-table-content">
                            <mat-table *ngIf="report" [dataSource]="report" class="table">
                                <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.name">
                                    <mat-header-cell *cdkHeaderCellDef>{{ column.label }}</mat-header-cell>
                                    <mat-cell *cdkCellDef="let row">{{ row[column.name] }}</mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;" [attr.type]="row.type"
                                    [attr.rate]="row.total_rate"></mat-row>
                            </mat-table>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<ng-template #schemaInfo let-schema="schema">
    <div class="schema-info">
        <div class="schema-info-field">
            <div class="schema-info-field-name">ID</div>
            <div class="schema-info-field-value">{{schema.id}}</div>
        </div>
        <div class="schema-info-field">
            <div class="schema-info-field-name">Name</div>
            <div class="schema-info-field-value">{{schema.name}}</div>
        </div>
        <div class="schema-info-field">
            <div class="schema-info-field-name">Description</div>
            <div class="schema-info-field-value">{{schema.description}}</div>
        </div>
        <div class="schema-info-field">
            <div class="schema-info-field-name">Version</div>
            <div class="schema-info-field-value">{{schema.version}}</div>
        </div>
        <div class="schema-info-field">
            <div class="schema-info-field-name">Topic</div>
            <div class="schema-info-field-value">{{schema.topicId}}</div>
        </div>
        <div class="schema-info-field">
            <div class="schema-info-field-name">Type</div>
            <div class="schema-info-field-value">{{schema.iri}}</div>
        </div>
        <div class="schema-info-field">
            <div class="schema-info-field-name">Policy</div>
            <div class="schema-info-field-value">{{schema.policy}}</div>
        </div>
    </div>
</ng-template>

<ng-template #propInfo let-report="report" let-index="index">
    <div class="prop">
        <div class="prop-name">Total Rate: </div>
        <div class="prop-value" [attr.rate]="report.total_rate">{{report.total_rate}}</div>
    </div>

    <div class="prop-delimiter"></div>

    <div class="prop-table">
        <div class="prop-table-header">Properties: </div>
        <div class="prop-table-body">
            <div *ngFor="let prop of report.properties" class="prop-table-row" [attr.type]="prop.type"
                [attr.offset]="prop.lvl" [attr.index]="index">
                <div class="prop-table-name" [style.padding-left.px]="10*prop.lvl">{{prop.name}}:</div>
                <div class="prop-table-value">
                    <span *ngIf="prop.items[index]" [attr.prop-type]="prop.items[index].type">
                        {{prop.items[index].value}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="prop-delimiter"></div>
</ng-template>