<h1 mat-dialog-title>{{this.title}}</h1>
<div mat-dialog-content class="content">
    <div *ngIf="this.description" class="description">
        {{this.description}}
    </div>
    <div *ngIf="this.descriptions" class="description">
        <div *ngFor="let item of this.descriptions">
            {{item}}
        </div>
    </div>
    <div class="buttons" mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="onSubmit()">{{submitButton}}</button>
        <button mat-button type="button" (click)="onNoClick()">{{cancelButton}}</button>
    </div>
</div>