<div class="g-dialog-content">
    <div class="g-dialog-header">
        <div class="g-dialog-cancel">
            <div class="g-dialog-cancel-btn" (click)="onNoClick()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <div class="g-dialog-title">
            {{title}}
        </div>
    </div>
    <div class="g-dialog-body">
        <div class="context">
            <div *ngIf="loading" class="loading">
                <mat-spinner></mat-spinner>
            </div>
            <!-- <div class="header">{{text}}</div> -->
            <div *ngIf="results" class="results">
                <div class="results-header">
                    Common
                </div>

                <div class="results-info">
                    <div class="results-info-header">Created documents:</div>
                    <div>{{info.documents}}</div>
                </div>
                <div class="results-info">
                    <div class="results-info-header">Minted tokens:</div>
                    <div>{{info.tokens}}</div>
                </div>
                <div class="results-info">
                    <div class="results-info-header">Total rate:</div>
                    <div [attr.rate-color]="total">{{total}}%</div>
                </div>

                <div class="results-header">
                    Documents
                </div>
                <div class="results-body">
                    <div *ngFor="let item of results; let i=index" class="results-item">
                        <div class="document-type">{{item.type}}</div>
                        <div class="document-schema">{{item.schema}}</div>
                        <div class="document-rate" [attr.rate-color]="item.rate">{{item.rate}}</div>
                        <div class="document-view">
                            <a (click)="openDocument(item)">View Document</a>
                        </div>
                    </div>
                </div>
                <div class="dialog-actions">
                    <button mat-raised-button color="primary" (click)="onDetails()">Details</button>
                    <button mat-button type="button" (click)="onFinish()">Finish</button>
                </div>
            </div>
        </div>
    </div>
</div>