<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow" *ngIf="properties.blockType=='interfaceStepBlock'">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Cyclic</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.cyclic" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>

    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'main')" [attr.collapse]="propHidden.main">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">UI</td>
        <td class="propHeaderCell"></td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Title</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.uiMetaData.title" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Type</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.uiMetaData.type" [disabled]="readonly" (change)="onSave()">
                <mat-option value="blank">BLANK</mat-option>
                <mat-option value="tabs">TABS</mat-option>
            </mat-select>
        </td>
    </tr>
</table>