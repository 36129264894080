<div class="dialog-body">
    <form (ngSubmit)="onSubmit()" [formGroup]="dataForm">
        <div class="form-input-container">
            <label class="form-label" htmlFor="name">* Name</label>
            <input formControlName="name" id="name" pInputText type="text"/>
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="sectoralScope">Sectoral scope</label>
            <p-dropdown
                    #operationDropdown
                    [options]="sectoralScopeOptions"
                    formControlName="sectoralScope"
                    id="sectoralScope"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Not selected"
            >
            </p-dropdown>
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="projectScale">Project Scale</label>
            <p-dropdown
                    #operationDropdown
                    [options]="projectScaleOptions"
                    formControlName="projectScale"
                    id="projectScale"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Not selected"
            >
            </p-dropdown>
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="applicabilityConditions">Conditions for applicability</label>
            <input formControlName="applicabilityConditions" id="applicabilityConditions" pInputText type="text"/>
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="detailsUrl">URL on policy details page</label>
            <input formControlName="detailsUrl" id="detailsUrl" pInputText type="text"/>
        </div>
        <div class="form-input-container">
            <label htmlFor="tag">* Tag</label>
            <input
                    formControlName="policyTag"
                    id="tag"
                    pInputText
                    type="text"
            />
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="typicalProjects">Typical project(s)</label>
            <input formControlName="typicalProjects" id="typicalProjects" pInputText type="text"/>
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="topicDescription">Topic Description</label>
            <input
                    formControlName="topicDescription"
                    id="topicDescription"
                    pInputText
                    type="text"
            />
        </div>
        <div class="form-input-container">
            <label class="form-label" for="description">Description</label>
            <textarea
                    cols="30"
                    formControlName="description"
                    id="description"
                    pInputTextarea
                    rows="3"
            ></textarea>
        </div>

        <h3>Categorization</h3>
        <div class="form-input-container">
            <label class="form-label" htmlFor="appliedTechnologyType">Applied Technology Type/Measure</label>
            <p-dropdown
                    #operationDropdown
                    [options]="appliedTechnologyTypeOptions"
                    formControlName="appliedTechnologyType"
                    id="appliedTechnologyType"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Not selected"
            >
            </p-dropdown>
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="migrationActivityType">Mitigation Activity Type</label>
            <p-multiSelect
                    #operationDropdown
                    [options]="migrationActivityTypeOptions"
                    defaultLabel="Not selected"
                    display="chip"
                    formControlName="migrationActivityType"
                    id="migrationActivityType"
                    optionLabel="name"
                    optionValue="id"
            >
            </p-multiSelect>
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="subType">Sub Type</label>
            <p-multiSelect
                    #operationDropdown
                    [options]="subTypeOptions"
                    defaultLabel="Not selected"
                    display="chip"
                    formControlName="subType"
                    id="subType"
                    optionLabel="name"
                    optionValue="id"
            >
            </p-multiSelect>
        </div>

        <h3>Important parameters</h3>
        <div class="form-input-container">
            <label class="form-label" htmlFor="atValidation">At validation</label>
            <input formControlName="atValidation" id="atValidation" pInputText type="text"/>
        </div>
        <div class="form-input-container">
            <label class="form-label" htmlFor="monitored">Monitored</label>
            <input formControlName="monitored" id="monitored" pInputText type="text"/>
        </div>
    </form>
</div>
<div class="dialog-footer">
    <div class="action-buttons">
        <div class="cancel-button">
            <p-button
                    (click)="onNoClick()"
                    label="Cancel"
                    styleClass="p-button-outlined"
            ></p-button>
        </div>
        <div>
            <p-button (click)="onSubmit()" [disabled]="!dataForm.valid" label="Create"></p-button>
        </div>
    </div>
</div>
