<h1 mat-dialog-title class="dialog-title">{{title}}</h1>
<div mat-dialog-content class="dialog-content">
    <div class="content">
        <div class="links" *ngIf="type === 'LINK'">
            <hedera-explorer 
                *ngFor="let link of links"
                [type]="link.type" 
                [params]="link.params" 
                [subType]="link.subType"
                [subParams]="link.subParams">{{link.value}}</hedera-explorer>
        </div>
        <div class="text" *ngIf="type === 'TEXT'">
            {{text}}
        </div>
        <div class="json" *ngIf="type === 'JSON'">
            {{json}}
        </div>
    </div>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button type="button" (click)="onClick()">Cancel</button>
</div>