<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>

<div class="container">

    <div>
        <button (click)="goToPage()"
                [iconPos]="'left'"
                [icon]="'pi pi-chevron-left'"
                class="button secondary height-40 margin-bottom-16"
                label="Back to User Management"
                pButton 
                type="button"></button>
    </div>

    <div class="page-title">{{username}}</div>

    <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="onChange($event)">
        <mat-tab label="User Roles">
            <div class="permissions-content">
                <div class="selected-roles">
                    <div class="role-header">Selected Roles</div>
                    <div *ngIf="roleGroups?.length" class="roles-content">
                        <div *ngFor="let group of roleGroups" 
                                class="role-content"
                                matTooltipPosition="above"
                                matTooltip="Assigned by another user"
                                [matTooltipDisabled]="group.own"
                                [attr.own]="group.own"
                                [attr.editable]="group.canEdit">
                            <div class="role-name">{{group.roleName}}</div>
                            <div class="role-icon" (click)="onDeleteRole(group.roleId)">
                                <svg-icon class="svg-icon-16" 
                                    src="/assets/images/icons/16/circle-close.svg" 
                                    svgClass="close-icon-color"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="roles">
                    <p-multiSelect 
                            [filter]="true" 
                            [options]="roles"
                            optionLabel="name"
                            optionValue="id"
                            optionDisabled="disabled"
                            [(ngModel)]="roleIds"
                            emptyMessage="Search by Role name"
                            placeholder="Search by Role name"
                            appendTo="body"
                            (onPanelHide)="onChangeRole()">
                        <ng-template let-option pTemplate="selectedItem">
                            <div class="dropdown-item">{{ option.name }}</div>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                            <div class="dropdown-item">{{ option.name }}</div>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="selected-roles">
                    <div class="permissions-header">Preview</div>
                </div>
        
                <div *ngIf="group" class="categories">
                    <div *ngFor="let category of group.categories" class="category">
                        <div class="category-header">
                            <div class="category-name">{{category.name}}</div>
                        </div>
                        <div class="category-actions">
                            <div class="category-actions-header">ACTIONS</div>
                            <div class="permissions">
                                <ng-container *ngIf="category.type ==='Access'">
                                    <div *ngFor="let action of category.actions; let i=index" class="permission big perm-{{i}}">{{action}}</div>
                                </ng-container>
                                <ng-container *ngIf="category.type ==='Permissions'">
                                    <div *ngFor="let action of category.actions; let i=index" class="permission perm-{{i}}">{{action}}</div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="category-body">
                            <div *ngFor="let entity of category.entities" class="entity">
                                <div class="entity-name">{{entity.name}}</div>
                                <div class="permissions">
                                    <ng-container *ngIf="entity.type ==='all'">
                                        <div *ngFor="let action of entity.actions;" class="all-permission">
                                            <mat-checkbox 
                                                color="primary" 
                                                *ngIf="action"
                                                matTooltipPosition="above"
                                                [matTooltip]="action.tooltip"
                                                [matTooltipDisabled]="!action.tooltip"
                                                [formControl]="action.control"></mat-checkbox>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="entity.type ==='checkbox'">
                                        <div *ngFor="let action of entity.actions; let i=index" class="permission perm-{{i}}">
                                            <mat-checkbox 
                                                *ngIf="action"
                                                color="primary" 
                                                matTooltipPosition="above"
                                                [matTooltip]="action.tooltip"
                                                [matTooltipDisabled]="!action.tooltip"
                                                [formControl]="action.control"></mat-checkbox>
                                            <div *ngIf="!action" class="permission-background"></div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="entity.type ==='radio'">
                                        <div *ngFor="let action of entity.actions; let i=index" class="permission big perm-{{i}}">
                                            <mat-checkbox 
                                                *ngIf="action"
                                                color="primary" 
                                                matTooltipPosition="above"
                                                [matTooltip]="action.tooltip"
                                                [matTooltipDisabled]="!action.tooltip"
                                                [formControl]="action.control"></mat-checkbox>
                                            <div *ngIf="!action" class="permission-background"></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="role-footer">
                    <button (click)="onCancelRole()" 
                            class="button secondary" 
                            label="Cancel" 
                            pButton
                            type="button"></button>
                    <button (click)="onSaveRole()" 
                            [disabled]="!needSave"  
                            label="Save" 
                            pButton
                            class="button" 
                            type="button"></button>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Assigned Policies">
            <div class="tab-container">
                <div class="filters-container">
                    <div class="filter-container">
                        <p-dropdown 
                            (onChange)="onFilter()" 
                            [(ngModel)]="statusFilterValue" 
                            [options]="statusFilterOption">
                            <ng-template pTemplate="selectedItem">
                                <div *ngIf="statusFilterValue">
                                    <span class="dropdown-label">Status</span> 
                                    <span class="dropdown-item">
                                        {{ statusFilterValue }}
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template let-option pTemplate="item">
                                <div class="dropdown-item">
                                    {{ option.name }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <div class="grid-container">
                    <table>
                        <tr class="grid-header">
                            <th class="header-cell-text cell-size-id">NAME</th>
                            <th class="header-cell-text cell-size-id">Description</th>
                            <th class="header-cell-text cell-size-id">Status</th>
                            <th class="header-cell-text cell-size-id">Version</th>
                            <th class="header-cell-text cell-size-assigned">
                                <mat-checkbox 
                                    color="primary" 
                                    [checked]="allPolicy" 
                                    (change)="assignAllPolicy()">Assigned to This User</mat-checkbox>
                                
                            </th>
                        </tr>
                        <tbody>
                            <ng-container *ngFor="let row of policyPage">
                                <tr class="grid-row">
                                    <td class="row-cell-text cell-size-id">{{ row.name }}</td>
                                    <td class="row-cell-text cell-size-id">{{ row.description }}</td>
                                    <td class="row-cell-text cell-size-id">
                                        <div class="chip chip-color-{{getColor(row.status, !!row.discontinuedDate)}}">
                                            {{ getLabelStatus(row.status, !!row.discontinuedDate) }}
                                        </div>
                                    </td>
                                    <td class="row-cell-text cell-size-id">{{ row.version }}</td>
                                    <td class="row-cell-text cell-size-assigned">
                                        <mat-checkbox color="primary" 
                                            [checked]="row.assigned" 
                                            [disabled]="!row._canAssign" 
                                            (change)="assignPolicy(row)"></mat-checkbox>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <app-paginator 
                        [pageIndex]="pageIndex" 
                        [pageSize]="pageSize" 
                        [length]="policyCount"
                        (page)="onPolicyPage($event)"
                    ></app-paginator>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>

