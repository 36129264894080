<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Type</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.type" [disabled]="readonly" (change)="onSave()">
                <mat-option value="selector">Selector</mat-option>
                <mat-option value="download">Download</mat-option>
                <mat-option value="dropdown">Dropdown</mat-option>
            </mat-select>
        </td>
    </tr>

    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'main')" [attr.collapse]="propHidden.main">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">UI</td>
        <td class="propHeaderCell"></td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Title</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.uiMetaData.title" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>

    <ng-container *ngIf="properties.type == 'selector'">
        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Field</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.field" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'optionsGroup')"
                [attr.collapse]="propHidden.optionsGroup">
                <mat-icon *ngIf="properties.uiMetaData.options.length">arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">Options</td>
            <td class="propRowCell">
                <div class="propAdd" (click)="addOptions()">
                    <mat-icon>add</mat-icon>
                    <span>Add Options</span>
                </div>
            </td>
        </tr>
        <ng-container *ngFor="let option of properties.uiMetaData.options; let i=index">
            <tr class="propRow subRow" [attr.collapse]="propHidden.main || propHidden.optionsGroup">
                <td class="propRowCol cellCollapse" (click)="onHide(propHidden.options, i)" [attr.collapse]="propHidden.options[i]">
                    <mat-icon>arrow_drop_down</mat-icon>
                </td>
                <td class="propRowCell cellName">Options {{i}}</td>
                <td class="propRowCell">{{option.name}}</td>
            </tr>

            <tr class="propRow subRow-2"
                [attr.collapse]="propHidden.main || propHidden.optionsGroup || propHidden.options[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">Option Tag</td>
                <td class="propRowCell">
                    <input class="prop-input" [(ngModel)]="option.tag" [readonly]="readonly" (blur)="onSave()">
                </td>
            </tr>
            <tr class="propRow subRow-2"
                [attr.collapse]="propHidden.main || propHidden.optionsGroup || propHidden.options[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">Name</td>
                <td class="propRowCell">
                    <input class="prop-input" [(ngModel)]="option.name" [readonly]="readonly" (blur)="onSave()">
                </td>
            </tr>
            <tr class="propRow subRow-2"
                [attr.collapse]="propHidden.main || propHidden.optionsGroup || propHidden.options[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">Value</td>
                <td class="propRowCell">
                    <input class="prop-input" [(ngModel)]="option.value" [readonly]="readonly" (blur)="onSave()">
                </td>
            </tr>
            <tr class="propRow subRow-2"
                [attr.collapse]="propHidden.main || propHidden.optionsGroup || propHidden.options[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">User</td>
                <td class="propRowCell">
                    <mat-select [(value)]="option.user" [disabled]="readonly" (change)="onSave()">
                        <mat-option value="owner">Owner</mat-option>
                        <mat-option value="current">Current</mat-option>
                    </mat-select>
                </td>
            </tr>
            <tr class="propRow subRow-2"
                [attr.collapse]="propHidden.main || propHidden.optionsGroup || propHidden.options[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">UI Class</td>
                <td class="propRowCell">
                    <input class="prop-input" [(ngModel)]="option.uiClass" [readonly]="readonly" (blur)="onSave()">
                </td>
            </tr>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="properties.type == 'dropdown'">
        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Button Content</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.uiMetaData.content" [readonly]="readonly"
                    (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'dropdownGroup')"
                [attr.collapse]="propHidden.dropdownGroup">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">Dropdown Options</td>
            <td class="propRowCell">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.main|| propHidden.dropdownGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Option Name</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.name" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.main|| propHidden.dropdownGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Option Value</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.value" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.main|| propHidden.dropdownGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Field</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.field" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>

    <ng-container *ngIf="properties.type == 'download'">
        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Button Content</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.uiMetaData.content" [readonly]="readonly"
                    (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'fileGroup')" [attr.collapse]="propHidden.fileGroup">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">File Options</td>
            <td class="propRowCell">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.main|| propHidden.fileGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Schema</td>
            <td class="propRowCell">
                <select-schema
                    [schemas]="schemas" 
                    [(value)]="properties.schema"
                    [disabled]="readonly" 
                    (change)="onSave()"
                    ></select-schema>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.main|| propHidden.fileGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Target Url</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.targetUrl" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>
</table>
