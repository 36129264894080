<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Type</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.type" [disabled]="readonly" (change)="onSave()">
                <mat-option value="dropdown">Dropdown</mat-option>
            </mat-select>
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Can Be Empty</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.canBeEmpty" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Field</td>
        <td class="propRowCell">
            <document-path [(value)]="properties.field" [readonly]="readonly" [displayTooltip]="true"
                (change)="onSave()"></document-path>
        </td>
    </tr>

    <ng-container *ngIf="properties.type == 'dropdown'">
        <tr class="propRow">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'dropdownGroup')"
                [attr.collapse]="propHidden.dropdownGroup">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">Dropdown Options</td>
            <td class="propRowCell">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.dropdownGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Option Name</td>
            <td class="propRowCell">
                <document-path [(value)]="properties.optionName" [readonly]="readonly" [displayTooltip]="true"
                    (change)="onSave()"></document-path>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.dropdownGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Option Value</td>
            <td class="propRowCell">
                <document-path [(value)]="properties.optionValue" [readonly]="readonly" [displayTooltip]="true"
                    (change)="onSave()"></document-path>
            </td>
        </tr>
    </ng-container>

    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'main')" [attr.collapse]="propHidden.main">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">UI</td>
        <td class="propHeaderCell"></td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Title</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.uiMetaData.title" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Button Content</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.uiMetaData.content" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>
</table>