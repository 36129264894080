<div class="g-dialog-content">
    <div class="g-dialog-header">
        <div class="g-dialog-title">
            Schema Import Preview
        </div>
    </div>
    <div class="g-dialog-body">
        <div class="context">
            <div *ngIf="loading" class="loading">
                <div class="preloader-image preloader-image-l-size"></div>
            </div>

            <div class="field" *ngIf="schemaType === 'policy'">
                <div class="field-header">Policy</div>
                <div>
                    <mat-form-field appearance="outline" class="{{ topicId ? '' : 'mat-form-field-invalid'}}" style="min-width: 383px">
                        <mat-label>* Policy</mat-label>
                        <mat-select [(value)]="topicId" class="{{ topicId ? '' : 'mat-select-invalid'}}" >
                            <mat-option *ngFor="let policy of policies" [value]="policy.topicId">{{policy.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="field" *ngIf="schemaType === 'tool'">
                <div class="field-header">Tool</div>
                <div>
                    <mat-form-field appearance="outline" class="{{ topicId ? '' : 'mat-form-field-invalid'}}" style="min-width: 383px">
                        <mat-label>* Tool</mat-label>
                        <mat-select [(value)]="topicId" class="{{ topicId ? '' : 'mat-select-invalid'}}" >
                            <mat-option *ngFor="let tool of tools" [value]="tool.topicId">{{tool.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="field-header">Schemas</div>
            <div *ngFor="let schema of schemas">
                <div class="schema">
                    <div class="schema-header">{{schema.name}}</div>

                    <div class="version-container">
                        <div class="version-container">
                            <div *ngIf="schema.version" class="field">
                                <div class="field-name">Version</div>
                                <div class="field-value">
                                    {{ schema.version }}
                                </div>
                            </div>
                            <app-new-versions
                                class="version-container__new-versions"
                                type="schema"
                                (onClick)="onNewVersionClick($event)"
                                [newVersions]="schema.newVersions"
                            ></app-new-versions>
                        </div>
                    </div>

                    <div *ngIf="schema.description" class="field-last">
                        <div class="field-name">Description</div>
                        <div class="field-value">{{schema.description}}</div>
                    </div>
                </div>
            </div>

            <div *ngIf="errors?.length" class="fields-container">
                <div class="field-header">Warning & Errors</div>
                <div class="field-array">
                    <div *ngFor="let error of errors" class="field-array-item error-type-{{error.type}}">
                        <div>{{error.worksheet}}</div>
                        <div>{{error.__path}}</div>
                        <div>{{error.text}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="cancel-button">
            <p-button
                    (click)="onClose()"
                    label="Cancel"
                    styleClass="p-button-outlined"
            ></p-button>
        </div>
        <div>
            <p-button (click)="onImport()" [disabled]="!topicId" label="Import"></p-button>
        </div>
    </div>
</div>
