<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Document Type</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.documentType" [disabled]="readonly" (change)="onSave()">
                <mat-option value="vc-document">VC Document</mat-option>
                <mat-option value="vp-document">VP Document</mat-option>
                <mat-option value="related-vc-document">Related VC Document</mat-option>
                <mat-option value="related-vp-document">Related VP Document</mat-option>
            </mat-select>
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Check Schema</td>
        <td class="propRowCell">
            <select-schema
                [schemas]="schemas"
                [(value)]="properties.schema"
                [disabled]="readonly"
                (change)="onSave()"
                ></select-schema>
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Check Owned by User</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.checkOwnerDocument" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Check Owned by Group</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.checkOwnerByGroupDocument"
                [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Check Assigned to User</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.checkAssignDocument" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Check Assigned to Group</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.checkAssignByGroupDocument"
                [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'conditionsGroup')"
            [attr.collapse]="propHidden.conditionsGroup">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Conditions</td>
        <td class="propHeaderCell">
            <div class="propAdd" (click)="addCondition()">
                <mat-icon>add</mat-icon>
                <span>Add Condition</span>
            </div>
        </td>
    </tr>
    <ng-container *ngFor="let condition of properties.conditions; let i=index">
        <tr class="propRow" [attr.collapse]="propHidden.conditionsGroup">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden.conditions, i)"
                [attr.collapse]="propHidden.conditions[i]">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">Condition {{i}}</td>
            <td class="propRowCell">
                <span *ngIf="condition.field && condition.type && condition.value"
                      [title]="condition.field + ' ' + condition.type + ' ' + condition.value">
                    {{condition.field}} {{condition.type}} {{condition.value}}
                </span>
                <span class="remove-prop" [attr.readonly]="readonly" (click)="removeCondition(i)">
                    <mat-icon>delete</mat-icon>
                </span>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.conditionsGroup || propHidden.conditions[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Type</td>
            <td class="propRowCell">
                <mat-select [(value)]="condition.type" [disabled]="readonly" (change)="onSave()">
                    <mat-option value="equal">Equal</mat-option>
                    <mat-option value="not_equal">Not Equal</mat-option>
                    <mat-option value="in">In</mat-option>
                    <mat-option value="not_in">Not In</mat-option>
                </mat-select>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.conditionsGroup || propHidden.conditions[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Field</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="condition.field" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.conditionsGroup || propHidden.conditions[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Value</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="condition.value" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>
</table>
