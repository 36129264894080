<div class="content">
    <div class="action-select-step">
        <div class="login-header">
            Unsaved changes
        </div>
        <button mat-button (click)="save()">
            <span class="action-name">Save</span>
        </button>
        <button mat-button (click)="cancel()">
            <span class="action-name">Cancel</span>
        </button>
    </div>
</div>
