<div class="artifact-container">
    <div (cdkDropListDropped)="changeArtifactPosition($event)" *ngIf="this.currentBlock.artifacts.length > 0" [cdkDropListData]="this.currentBlock.artifacts"
         cdkDropList class="artifact-list"
        [cdkDropListDisabled]="readonly">
        <div class="artifact-row" *ngFor="let artifact of this.currentBlock.artifacts; let i = index;" cdkDrag>
            <mat-icon>drag_handle</mat-icon>
            {{ artifact.name }} ({{ artifact.type }})
            <span class="remove-prop" [attr.readonly]="readonly" (click)="removeArtifact(artifact)">
                <mat-icon>delete</mat-icon>
            </span>
        </div>
    </div>
    <button [matMenuTriggerFor]="menu" class="add-artifact-button" mat-button>
        <mat-icon>add</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <ng-container *ngFor="let artifact of policyArtifacts">
            <button (click)="addArtifact(artifact)" [disabled]="readonly" mat-menu-item>
                {{artifact.name}} ({{artifact.type}})
            </button>
        </ng-container>
    </mat-menu>
</div>
