<div class="dialog-body">
    <div *ngIf="loading" class="loading">
        <div class="preloader-image preloader-image-l-size"></div>
    </div>

    <div *ngIf="policy">
        <div *ngIf="policies.length" class="policy-version-container">
            <label class="form-label" htmlFor="versionOf">Version of</label>
            <p-dropdown
                    [(ngModel)]="versionOfTopicId"
                    [options]="policies"
                    appendTo="body"
                    class="version-of-dropdown"
                    id="versionOf"
                    optionLabel="name"
                    optionValue="topicId"
                    placeholder="Select Policy"
            ></p-dropdown>
        </div>

        <div class="version-container">
            <div *ngIf="policy.version" class="field">
                <div class="field-name">Version</div>
                <div class="field-value">{{ policy.version }}</div>
            </div>
            <app-new-versions
                    (onClick)="onNewVersionClick($event)"
                    [newVersions]="newVersions"
                    class="version-container__new-versions"
                    type="policy"
            ></app-new-versions>
        </div>

        <div class="fields-container">
            <div class="field-name">Description</div>
            <div class="field-value">{{ policy.description }}</div>
        </div>

        <div class="fields-container">
            <div class="field-name">Tag</div>
            <div class="field-value">{{ policy.policyTag }}</div>
        </div>

        <div class="fields-container">
            <div class="field-name">Creator</div>
            <div [title]="policy.owner" class="field-value">
                {{ policy.owner }}
            </div>
        </div>

        <div class="fields-container">
            <div class="field-name">Involved Roles</div>
            <div class="field-value">Standard Registry, {{ policyGroups }}</div>
        </div>

        <div *ngIf="schemas" class="fields-container">
            <div class="field-name">Schemas Overview</div>
            <div class="field-value full">{{ schemas }}</div>
        </div>

        <div *ngIf="tokens" class="fields-container">
            <div class="field-name">Tokens</div>
            <div class="field-value">{{ tokens }}</div>
        </div>

        <div *ngIf="similar" class="fields-container">
            <div class="field-name">Similar Policies</div>
            <div class="field-value">{{ similar }}</div>
        </div>

        <div *ngIf="toolConfigs" class="fields-container">
            <div class="field-name">Tools</div>
            <form [formGroup]="toolForm">
                <div class="field-input p-float-label" *ngFor="let toolConfig of toolConfigs">
                    <input id="float-input" class="input" type="text" pInputText [formControlName]="toolConfig.messageId">
                    <label for="float-input">{{toolConfig.name}}</label>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="module" class="policy">
        <div class="fields-container">
            <div class="field-name">Description</div>
            <div class="field-value">{{ module.description }}</div>
        </div>

        <div class="fields-container">
            <div class="field-name">Creator</div>
            <div [title]="module.owner" class="field-value">
                {{ module.owner }}
            </div>
        </div>
    </div>

    <div *ngIf="tool" class="policy">
        <div class="policy-header">{{ tool.name }}</div>

        <div class="delimiter"></div>

        <div class="field">
            <div class="field-name">Description</div>
            <div class="field-value">{{ tool.description }}</div>
        </div>

        <div class="field">
            <div class="field-name">Creator</div>
            <div [title]="tool.owner" class="field-value">{{ tool.owner }}</div>
        </div>

        <div *ngIf="!isFile" class="field">
            <div class="field-name">Tools</div>
            <div class="field-value">{{ tools }}</div>
        </div>

        <div *ngIf="isFile && toolConfigs && toolForm" class="fields-container">
            <div class="field-name">Tools</div>
            <form [formGroup]="toolForm!">
                <div class="field-input p-float-label" *ngFor="let toolConfig of toolConfigs">
                    <input id="float-input" class="input" type="text" pInputText [formControlName]="toolConfig.messageId">
                    <label for="float-input">{{toolConfig.name}}</label>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="xlsx" class="policy">
        <div *ngIf="schemas" class="fields-container">
            <div class="field-name">Schemas Overview</div>
            <div class="field-value full">{{ schemas }}</div>
        </div>
        <div *ngIf="tools" class="fields-container">
            <div class="field-name">Tools Overview</div>
            <div class="field-value full">{{ tools }}</div>
        </div>
        <div *ngIf="errors?.length" class="fields-container">
            <div class="field-name">Warning & Errors</div>
            <div class="field-array">
                <div *ngFor="let error of errors" class="field-array-item error-type-{{error.type}}">
                    <div>{{error.worksheet}}</div>
                    <div>{{error.__path}}</div>
                    <div>
                        <div>{{error.text}}</div>
                        <div *ngIf="error.message && error.message !== error.text">{{error.message}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="dialog-footer">
    <div class="action-buttons">
        <div class="cancel-button">
            <button
                    (click)="onClose()"
                    class="button secondary"
                    label="Close"
                    pButton
            ></button>
        </div>
        <div [ngSwitch]="!!(xlsx && errors?.length)">
            <button
                    *ngSwitchCase="true"
                    (click)="onImport()"
                    class="button"
                    label="Skip & Import"
                    pButton
            ></button>
            <button
                    *ngSwitchCase="false"
                    [disabled]="!valid"
                    (click)="onImport()"
                    class="button"
                    label="Import"
                    pButton
            ></button>
        </div>
    </div>
</div>