<div class="tooltip-container">
    <div class="item">
        <div class="color-rectangle color-blue"></div>
        <span>Policies</span>
    </div>
    <div class="item">
        <div class="color-rectangle color-green"></div>
        <span>Modules</span>
    </div>
</div>
<div class="drag-drop-container">
    <div class="item">
        <h3>All policies and modules</h3>
        <div #available="cdkDropList" (cdkDropListDropped)="drop($event)" [cdkDropListConnectedTo]="[result]"
             [cdkDropListData]="policiesAndModules" cdkDropList
             class="draggable-elements-container">
            <div *ngFor="let item of policiesAndModules" [attr.type]="item.type" cdkDrag class="item">
                <span cdkDragHandle class="re-order-handler"><mat-icon>drag_handle</mat-icon></span>
                <span>{{ item.name }}</span>
                <span (click)="move(item)" class="move-right-btn"><mat-icon>arrow_right_alt</mat-icon></span>
            </div>
        </div>
    </div>
    <div class="item">
        <h3>Suggestions priority order</h3>
        <div #result="cdkDropList" (cdkDropListDropped)="drop($event)" [cdkDropListConnectedTo]="[available]"
             [cdkDropListData]="suggestionsOrderPriority" cdkDropList
             class="draggable-elements-container">
            <div *ngFor="let item of suggestionsOrderPriority" [attr.type]="item.type" cdkDrag class="item">
                <span cdkDragHandle class="re-order-handler"><mat-icon>drag_handle</mat-icon></span>
                <span>{{ item.name }}</span>
                <span (click)="remove(item)" class="delete-btn"><mat-icon>delete</mat-icon></span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loading && isConfirmed && false" class="content">
    <div class="content-container">
        <div class="container">
            <h2>All policies and modules</h2>
            <div cdkDropList #available="cdkDropList" [cdkDropListData]="policiesAndModules"
                 (cdkDropListDropped)="drop($event)" [cdkDropListConnectedTo]="[result]" class="item-list">
                <div class="item-list-box" [attr.type]="item.type" *ngFor="let item of policiesAndModules" cdkDrag>
                    <span cdkDragHandle class="re-order-handler"><mat-icon>drag_handle</mat-icon></span>
                    <span>{{item.name}}</span>
                    <span class="move-right-btn" (click)="move(item)"><mat-icon>arrow_right_alt</mat-icon></span>
                </div>
            </div>
        </div>

        <div class="center-container">
            <div class="action-btn">
                <button (click)="apply()" color="primary" mat-flat-button>Apply settings</button>
            </div>
            <div>
                <mat-icon class="arrow-icon">arrow_right_alt</mat-icon>
            </div>
            <div class="action-btn">
                <button (click)="clear()" color="warn" mat-flat-button>Clear</button>
            </div>
        </div>

        <div class="container">
            <h2>Suggestions priority order</h2>
            <div cdkDropList #result="cdkDropList" [cdkDropListData]="suggestionsOrderPriority"
                 (cdkDropListDropped)="drop($event)" [cdkDropListConnectedTo]="[available]" class="item-list">
                <div *ngFor="let item of suggestionsOrderPriority" [attr.type]="item.type" cdkDrag
                     class="item-list-box">
                    <span cdkDragHandle class="re-order-handler"><mat-icon>drag_handle</mat-icon></span>
                    <span>{{item.name}}</span>
                    <span class="delete-btn" (click)="remove(item)"><mat-icon>delete</mat-icon></span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isConfirmed" class="not-exist">
    Before starting work you need to get DID <a [routerLink]="['/profile']">here</a>
</div>
<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>
