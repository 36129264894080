<div class="content" *ngIf="commonVisible">
    <ng-container *ngFor="let button of buttons" >
        <div *ngIf="button.type == 'selector' && button.visible && button.name">
            <div *ngIf="button.name" class="btn-option {{button.uiClass}}" (click)="onSelect(button)">
                {{button.name}}
            </div>
        </div>

        <div *ngIf="button.type === 'selector-dialog' && button.visible && button.name">
            <div *ngIf="button.name" class="btn-option {{button.uiClass}}" (click)="onSelectDialog(button)">
                {{button.name}}
            </div>
        </div>
    </ng-container>
</div>

<ng-template #preloader>
    <div class="preloader-image"></div>
</ng-template>

<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>