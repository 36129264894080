<div class="container">
    <ng-container [ngTemplateOutlet]="header"></ng-container>
    <ng-container *ngIf="!loading">
        <ng-container [ngTemplateOutlet]="actionsContainer"></ng-container>
        <ng-container [ngTemplateOutlet]="tableContainer"></ng-container>
    </ng-container>
    <div *ngIf="loading" class="loading">
        <div class="preloader-image preloader-image-l-size"></div>
    </div>
</div>

<ng-template #header>
    <div class="header">
        <h1>Logs</h1>
    </div>
</ng-template>

<ng-template #actionsContainer>
    <div class="actions-container">
        <form [formGroup]="searchForm">
            <div class="flex flex-column-gap-8" style="flex-wrap: wrap; row-gap: 8px;">
                <p-multiSelect #searchInput (onFilter)="onFilter($event)"
                               [filter]="true" [options]="attributes"
                               emptyMessage=""
                               formControlName="attributes" placeholder="Attributes">
                    <ng-template let-item pTemplate="selectedItem">
                        <div class="country-item country-item-value">
                            <span class="grey-6">{{ item }}</span>
                        </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <div class="country-item">
                            <div>{{ item }}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
                <span class="p-input-icon-right">
                    <i>
                        <svg-icon class="svg-icon-16" src="/assets/images/icons/16/search.svg" svgClass="color-search">
                        </svg-icon>
                    </i>
                    <input
                        [formControlName]="'message'"
                        class="search-input"
                        pInputText
                        placeholder="Message"
                    />
                </span>
                <p-dropdown [formControlName]="'type'" [options]="types">
                    <ng-template let-item pTemplate="selectedItem">
                        <div class="country-item country-item-value">
                            <span class="grey-6">Message Type {{ item.label }}</span>
                        </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <div class="country-item">
                            <div>{{ item.label }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <p-calendar [formControl]="dateRangeForm" [maxDate]="currentDate" [placeholder]="'Enter date range'"
                            [showIcon]="true" [showTime]="true" dateFormat="mm/dd/yy"
                            selectionMode="range"></p-calendar>
            </div>
        </form>
        <div class="flex flex-column-gap-8">
            <button
                (click)="onApply()"
                class="button"
                label="Apply"
                pButton
                type="button"
            ></button>
            <button
                (click)="clearValues()"
                class="button secondary"
                label="Clear filters"
                pButton
                type="button"
            ></button>
            <button
                (click)="onSave()"
                class="button"
                label="Save logs"
                pButton
                type="button"
            ></button>
        </div>
    </div>
</ng-template>

<ng-template #tableContainer>
    <ng-container *ngIf="logs && logs.length > 0; else noData">
        <div class="table-container">
            <table>
                <tr class="row-header">
                    <th>TYPE</th>
                    <th>DATE TIME</th>
                    <th>MESSAGE</th>
                    <th>ATTRIBUTES</th>
                    <th>DETAILS</th>
                </tr>
                <ng-container *ngFor="let log of logs">
                    <tr class="row-item">
                        <td>{{ log.type }}</td>
                        <td>{{ $any(log).datetime }}</td>
                        <td>
                            {{ log.message }}
                        </td>
                        <td>
                            {{ log.attributes }}
                        </td>
                        <td>
                            <button (click)="openDetails(log)" class="button secondary" pButton>Details</button>
                        </td>
                    </tr>
                </ng-container>
            </table>

            <app-paginator
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                [length]="totalCount"
                (page)="onPage($event)"
            ></app-paginator>

        </div>
    </ng-container>
</ng-template>

<ng-template #noData>
    <div class="not-exist">
        <svg-icon class="svg-icon-32" src="/assets/images/icons/32/list.svg" svgClass="disabled-color">
        </svg-icon>
        <span class="info-text-strong"
        >There were no Logs by current criteria</span
        >
        <span class="info-text"></span>
    </div>
</ng-template>
