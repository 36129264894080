<div class="content">
    <form *ngIf="!hasParam; else chainTpl" class="filters" [formGroup]="searchForm" (ngSubmit)="updateFilter()">
        <mat-form-field class="search-input" appearance="outline">
            <mat-label>HASH/ID:</mat-label>
            <input matInput type="text" placeholder="HASH/ID" formControlName="value">
            <button mat-icon-button matSuffix type="submit" [disabled]="!searchForm.valid">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </form>

    <ng-template #chainTpl>
        <div *ngIf="chain" class="display-chain">
            <div class="verifiable-presentation">
                <div *ngIf="vp" class="header">
                    <a routerLink="/audit" class="go-back-link">
                        <mat-icon>chevron_left</mat-icon>
                        {{vp.label}}: {{vp.id}}
                    </a>
                    Verifiable Presentation
                    <mat-icon>check_circle</mat-icon>

                    <a class="open-vp" (click)="openVPDocument(vp)">VP File</a>
                </div>
                <div *ngIf="!vp && chain" class="header">
                    <a routerLink="/audit" class="go-back-link">
                        <mat-icon>chevron_left</mat-icon>
                        {{chain[0].label}}: {{chain[0].id}}
                    </a>
                    <a class="open-vp" (click)="openVCDocument(chain[0])">File</a>
                </div>
                <div *ngIf="vpMint" class="vp-section">
                    <div class="vp-section-header">
                        Token & Issuer
                        <a class="open-vp" (click)="openJsonDocument(vpMint)">VC Files</a>
                    </div>
                    <div class="vp-table">
                        <div class="vp-field">
                            <div class="vp-field-label">Token</div>
                            <div class="vp-field-value">{{vpMint.tokenId}}</div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Issuer: Name</div>
                            <div class="vp-field-value" [title]="getIssuer(vpMint)">{{userMap[getIssuer(vpMint)]}}</div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Mint date</div>
                            <div class="vp-field-value">{{vpMint.date}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="vpPolicy" class="vp-section">
                    <div class="vp-section-header">
                        Policy Overview
                        <a class="open-vp" (click)="openVCDocument(vpPolicy)">VC Files</a>
                    </div>
                    <div class="vp-table">
                        <div class="vp-field">
                            <div class="vp-field-label">Policy Name</div>
                            <div class="vp-field-value">{{vpPolicy.name}}</div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Description</div>
                            <div class="vp-field-value">{{vpPolicy.description}}</div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Version</div>
                            <div class="vp-field-value">{{vpPolicy.version}}</div>
                        </div>
                        <div class="vp-field">
                            <div class="vp-field-label">Issuer: Name</div>
                            <div class="vp-field-value" [title]="getIssuer(vpPolicy)">{{userMap[getIssuer(vpPolicy)]}}</div>
                        </div>
                    </div>
                </div>
                <div class="vp-description">
                    This Carbon Offset Claim has met all the requirements as issued in the policy secured to this token.
                </div>
                <div *ngIf="vp" class="vp-signature">
                    <div class="vp-field-label">Verified Signature:</div>
                    <div class="vp-field-value">{{vp.document.proof.jws}}</div>
                </div>
            </div>
            <div class="container">
                <div class="scroll-right" (click)="onScrollButtonPress(scrollContainer, -150)">
                    <mat-icon style="margin-left: 32px">chevron_left</mat-icon>
                </div>
                <div class="chain-container" (wheel)="onWheel($event)" #scrollContainer>
                    <ng-container *ngFor="let item of chain">
                        <div class="chain-item item-type-{{item.type}}">
                            <div class="chain-title">
                                <ng-container [ngSwitch]="item.entity">
                                    <mat-icon *ngSwitchCase="'Policy'">format_list_bulleted</mat-icon>
                                    <mat-icon *ngSwitchCase="'Inverter'" svgIcon="iot"></mat-icon>
                                    <mat-icon *ngSwitchCase="'MRV'" svgIcon="mrv"></mat-icon>
                                    <mat-icon *ngSwitchCase="'Mint'" svgIcon="token"></mat-icon>
                                    <mat-icon *ngSwitchDefault>account_circle</mat-icon>
                                </ng-container>

                                {{item.schema}}
                            </div>
                            <div class="chain-id">
                                {{formatFields(item)}}
                            </div>
                            <div class="parties">
                                <div class="parties-label">Parties:</div>
                                <div class="parties-value">
                                    <div class="partie" [title]="getIssuer(item.document)">{{getParties(item)}}</div>
                                </div>
                            </div>
                            <div class="chain-document">
                                <a (click)="openVCDocument(item)">VC Files</a>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="scroll-right" (click)="onScrollButtonPress(scrollContainer, 150)">
                    <mat-icon>chevron_right</mat-icon>
                </div>
            </div>
        </div>
    </ng-template>

    <div *ngIf="loading" class="loading">
        <div class="preloader-image preloader-image-l-size"></div>
    </div>
</div>
