<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr *ngIf="properties.dataType" class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Data Type (deprecated)</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.dataType" [disabled]="readonly" (change)="onSave()">
                <mat-option value=""></mat-option>
                <mat-option value="vc-documents">Collection (VC)</mat-option>
                <mat-option value="did-documents">Collection (DID)</mat-option>
                <mat-option value="approve">Collection (Approve)</mat-option>
                <mat-option value="hedera">Hedera (Topic)</mat-option>
            </mat-select>
        </td>
    </tr>

    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">
            <!-- <span class="required-fields">*</span> -->
            Data Source
        </td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.dataSource" [disabled]="readonly"
                (selectionChange)="onDataSource($event)">
                <mat-option value="auto">Auto</mat-option>
                <mat-option value="database">Database</mat-option>
                <mat-option value="hedera">Hedera Topic</mat-option>
            </mat-select>
        </td>
    </tr>

    <ng-container *ngIf="properties.dataSource=='hedera'">
        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Hedera Topic</td>
            <td class="propRowCell">
                <mat-select [(value)]="properties.topic" [disabled]="readonly" (selectionChange)="selectTopic($event)">
                    <mat-option *ngFor="let topic of topics" [value]="topic.name" [matTooltip]="topic.description"
                        matTooltipPosition="above" matTooltipClass="guardian-tooltip small-guardian-tooltip">
                        {{topic.name}}
                    </mat-option>
                    <mat-option value="root">Policy Instance</mat-option>
                    <mat-option class="link" value="new">
                        <span class="mat-option-link">
                            <mat-icon>add</mat-icon>
                            <span>Create new topic</span>
                        </span>
                    </mat-option>
                </mat-select>
            </td>
        </tr>

        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Topic Owner</td>
            <td class="propRowCell">
                <mat-select [(value)]="properties.topicOwner" [disabled]="readonly" (change)="onSave()">
                    <mat-option value=""></mat-option>
                    <mat-option value="user">Current User</mat-option>
                    <mat-option value="owner">Document Owner</mat-option>
                    <mat-option value="issuer">Document Issuer</mat-option>
                </mat-select>
            </td>
        </tr>

        <tr class="propRow" [attr.collapse]="propHidden.main">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Memo</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.memo" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>

    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Entity Type</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.entityType" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>


    <tr class="propRow" [attr.collapse]="propHidden.main" *ngIf="['database', 'auto'].includes(properties.dataSource)">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Skip save state</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.skipSaveState" [readonly]="readonly">
        </td>
    </tr>

    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'optionGroup')" [attr.collapse]="propHidden.optionGroup">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Options</td>
        <td class="propHeaderCell">
            <div class="propAdd" (click)="addOption()">
                <mat-icon>add</mat-icon>
                <span>Add Option</span>
            </div>
        </td>
    </tr>

    <ng-container *ngFor="let option of properties.options; let i=index">
        <tr class="propRow" [attr.collapse]="propHidden.optionGroup">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden.options, i)" [attr.collapse]="propHidden.options[i]">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">Option {{i}}</td>
            <td class="propRowCell not-editable-text">
                {{option.name}}
                <span *ngIf="option.value">: {{option.value}}</span>
                <span class="remove-prop" [attr.readonly]="readonly" (click)="removeOption(i)">
                    <mat-icon>delete</mat-icon>
                </span>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.optionGroup || propHidden.options[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Option Name</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="option.name" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.optionGroup || propHidden.options[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Option Value</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="option.value" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>
</table>
