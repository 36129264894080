<div class="dialog-header">
    <div *ngIf="policy" class="header-container">
        <div class="header-text">Search based on</div>
        <div class="header-item">{{policy.name}}</div>
    </div>
    <div *ngIf="!policy" class="header-container">
        <div class="header-text">Search policies</div>
    </div>
    <div class="header-icon" (click)="onClose()">
        <svg-icon
            src="/assets/images/icons/close.svg"
            svgClass="close-icon-color">
        </svg-icon>
    </div>
</div>
<div class="dialog-body">
    <div class="context">
        <div *ngIf="loading" class="loading">
            <div class="preloader-image preloader-image-l-size"></div>
        </div>

        <form [formGroup]="filtersForm" class="filters">
            <div class="filter-row">
                <p-dropdown
                    [options]="types"
                    appendTo="body"
                    class="type-dropdown"
                    formControlName="type"
                    optionLabel="name" 
                    optionValue="value"
                    placeholder="Type"
                    (onChange)="changeType()">
                    <ng-template let-filterSelectedProperty pTemplate="selectedItem">
                        <span class="dropdown-type">Type:</span>
                        <span>{{ filterSelectedProperty.name }}</span>
                    </ng-template>
                </p-dropdown>
                <span class="p-input-icon-right">
                    <input
                        class="search-policy-input"
                        formControlName="policyName"
                        pInputText
                        placeholder="Search by keyword"
                        type="text" />
                    <i class="pi pi-search search-icon"></i>
                </span>
                <button (click)="clearFilters()"
                    type="button"
                    [disabled]="!filtersForm.dirty"
                    class="button secondary"
                    label="Clear filters"
                    pButton></button>
                <button (click)="showFilters()"
                    *ngIf="showMoreFilters"
                    type="button"
                    class="button secondary"
                    label="Filters ({{filtersCount}})"
                    pButton>
                    <svg-icon
                        class="icon-btn"
                        src="/assets/images/icons/filter-off.svg"
                        svgClass="off-icon-color">
                    </svg-icon>
                </button>
                <button (click)="showFilters()"
                    *ngIf="!showMoreFilters"
                    type="button"
                    class="button"
                    label="Filters ({{filtersCount}})"
                    pButton>
                    <svg-icon
                        class="icon-btn"
                        src="/assets/images/icons/filter-on.svg"
                        svgClass="on-icon-color">
                    </svg-icon>
                </button>
            </div>
            <div class="filter-group" [hidden]="!showMoreFilters">
                <div class="filter-row">
                    <div class="filter">
                        <p-dropdown
                            [options]="options"
                            appendTo="body"
                            class="type-dropdown"
                            formControlName="tokens"
                            optionLabel="name" 
                            optionValue="value"
                            placeholder="Tokens">
                            <ng-template let-filterSelectedProperty pTemplate="selectedItem">
                                <span class="dropdown-type">Minted Tokens:</span>
                                <span>{{ filterSelectedProperty.name }}</span>
                            </ng-template>
                        </p-dropdown>
                        <p-inputNumber 
                            [hidden]="!filtersForm.value.tokens"
                            class="type-number"
                            formControlName="tokensCount"
                            mode="decimal" 
                            [min]="0" ></p-inputNumber>
                    </div>
                    <div class="filter">
                        <p-dropdown
                            [options]="options"
                            appendTo="body"
                            class="type-dropdown"
                            formControlName="vcDocuments"
                            optionLabel="name" 
                            optionValue="value"
                            placeholder="VC Documents">
                            <ng-template let-filterSelectedProperty pTemplate="selectedItem">
                                <span class="dropdown-type">VC Documents:</span>
                                <span>{{ filterSelectedProperty.name }}</span>
                            </ng-template>
                        </p-dropdown>
                        <p-inputNumber 
                            [hidden]="!filtersForm.value.vcDocuments"
                            class="type-number"
                            formControlName="vcDocumentsCount"
                            mode="decimal" 
                            [min]="0" ></p-inputNumber>
                    </div>
                </div>
                <div class="filter-row">
                    <div class="filter">
                        <p-dropdown
                            [options]="options"
                            appendTo="body"
                            class="type-dropdown"
                            formControlName="vpDocuments"
                            optionLabel="name" 
                            optionValue="value"
                            placeholder="VP Documents">
                            <ng-template let-filterSelectedProperty pTemplate="selectedItem">
                                <span class="dropdown-type">VP Documents:</span>
                                <span>{{ filterSelectedProperty.name }}</span>
                            </ng-template>
                        </p-dropdown>
                        <p-inputNumber 
                            [hidden]="!filtersForm.value.vpDocuments"
                            class="type-number"
                            formControlName="vpDocumentsCount"
                            mode="decimal" 
                            [min]="0" ></p-inputNumber>
                    </div>
                    <div class="filter">
                        <span class="p-input-icon-right">
                            <input
                                class="search-policy-input"
                                formControlName="owner"
                                pInputText
                                placeholder="Policy Owner"
                                type="text" />
                            <i class="pi pi-search search-icon"></i>
                        </span>
                    </div>
                </div>
                <div class="filter-row" style="justify-content: flex-end">
                    <button (click)="applyFilters()"
                        type="submit"
                        [disabled]="!filtersForm.dirty"
                        class="button"
                        label="Apply Filters"
                        pButton></button>
                </div>
            </div>
        </form>
        <div *ngIf="list && list.length" class="grid-container">
            <div class="grid-header">
                <div class="col-64">
                    <input 
                        class="checkbox-input" 
                        type="checkbox" 
                        [checked]="selectedAll"  
                        (change)="onSelectAll()">
                </div>
                <div class="col-250">ID</div>
                <div class="col-auto">Name</div>
                <div class="col-200">Tags</div>
                <div class="col-80 search-extra-column">VC</div>
                <div class="col-80 search-extra-column">VP</div>
                <div class="col-80 search-extra-column">Tokens</div>
                <div *ngIf="policy" class="col-80">Rate</div>
                <div *ngIf="globalType" class="col-120"></div>
            </div>
            <div class="grid-body">
                <div *ngFor="let item of list" class="grid-row" (click)="onSelect(item)">
                    <div class="col-64">
                        <input 
                            class="checkbox-input" 
                            type="checkbox" 
                            [checked]="item._select" 
                            (change)="select()">
                    </div>
                    <div class="col-250">{{item.messageId || item.id}}</div>
                    <div class="col-auto">{{item.name}}</div>
                    <div class="col-200">{{item._tags}}</div>
                    <div class="col-80 search-extra-column">{{item.vcCount}}</div>
                    <div class="col-80 search-extra-column">{{item.vpCount}}</div>
                    <div class="col-80 search-extra-column">{{item.tokensCount}}</div>
                    <div *ngIf="policy" class="col-80 {{item._color}} color-field">{{item.rate}}%</div>
                    <div *ngIf="globalType" class="col-120 toolbars">
                        <p-button
                            (click)="importPolicy(item)"
                            class="toolbar-btn-primary"
                            [pTooltip]="'Import Policy'"
                            tooltipPosition="bottom">
                            <svg-icon src="/assets/images/icons/import.svg" svgClass="on-icon-color"></svg-icon>
                        </p-button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!list || !list.length" class="empty-grid">
            <div class="empty-grid-icon">
                <svg-icon
                    class="svg-icon-32"
                    src="/assets/images/icons/32/search.svg"
                    svgClass="empty-icon-color">
                </svg-icon>
            </div>
            <ng-container *ngIf="error">
                <div class="empty-grid-header">Not available</div>
                <div class="empty-grid-text">{{error}}</div>
            </ng-container>
            <ng-container *ngIf="!error">
                <div class="empty-grid-header">There are no policies</div>
                <div class="empty-grid-text">Please try to change or clear filters</div>
            </ng-container>
        </div>
    </div>
</div>
<div class="dialog-footer">
    <div class="action-buttons">
        <div class="cancel-button">
            <button (click)="onClose()" class="button secondary" label="Cancel" pButton></button>
        </div>
        <div>
            <button 
                (click)="onCompare()" 
                [disabled]="count<2"
                class="button" 
                label="Compare with selected ({{count}})" pButton>
                <svg-icon
                    class="icon2-btn"
                    src="/assets/images/icons/compare.svg"
                    [svgClass]="count > 1 ? 'on-icon-color':'disabled-icon-color'">
                </svg-icon>
            </button>
        </div>
    </div>
</div>