<div class="g-dialog-content">
    <div class="g-dialog-header">
        <div class="g-dialog-title" id="title">
            Multi Policy
        </div>
    </div>
    <div class="g-dialog-body">
        <div class="context">
            <div *ngIf="loading" class="loading">
                <div class="preloader-image preloader-image-l-size"></div>
            </div>

            <div>

                <div *ngIf="!exists">

                    <mat-radio-group aria-label="Select an option" [value]="policyType">
                        <div>
                            <div class="group-name">
                                <mat-radio-button value="Main" color="primary" (change)="onChange($event)">
                                    Create new multi policy
                                </mat-radio-button>
                            </div>
                            <div class="group-value" [attr.disabled]="policyType!=='Main'">
                                <div class="table-container" style="margin-bottom: 15px;color: #707070">
                                    <span>Type: </span>
                                    <span>Main Policy</span>
                                </div>
                                <div class="table-container" style="margin-bottom: 15px;color: #707070">
                                    <span>Main Policy: </span>
                                    <span>{{mainPolicyTopicId1}}</span>
                                </div>
                                <div class="table-container" style="margin-bottom: 15px;color: #707070">
                                    <span>Topic for synchronization: </span>
                                    <span>{{synchronizationTopicId1}}</span>
                                </div>
                                <mat-form-field appearance="outline">
                                    <mat-label>Link</mat-label>
                                    <input matInput [value]="link1" readonly="true">
                                </mat-form-field>
                            </div>
                        </div>
                        <div>
                            <div class="group-name">
                                <mat-radio-button value="Sub" color="primary" (change)="onChange($event)">
                                    Join an existing policy
                                </mat-radio-button>
                            </div>
                            <div class="group-value" [attr.disabled]="policyType!=='Sub'">
                                <div class="table-container" style="margin-bottom: 15px;color: #707070">
                                    <span>Type: </span>
                                    <span>Sub Policy</span>
                                </div>
                                <div class="table-container" style="margin-bottom: 15px;color: #707070">
                                    <span>Main Policy: </span>
                                    <span>{{mainPolicyTopicId2}}</span>
                                </div>
                                <div class="table-container" style="margin-bottom: 15px;color: #707070">
                                    <span>Topic for synchronization: </span>
                                    <span>{{synchronizationTopicId2}}</span>
                                </div>
                                <mat-form-field appearance="outline">
                                    <mat-label>Link</mat-label>
                                    <input matInput [(ngModel)]="link2" (ngModelChange)="onParse($event)">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-radio-group>

                </div>

                <div *ngIf="exists">
                    <div style="margin-left: 10px;" class="group-name">
                        Policy is already bound
                    </div>
                    <div class="group-value">
                        <div style="margin-bottom: 15px;color: #707070">
                            <span>Type: </span>
                            <span>{{policyType}} Policy</span>
                        </div>
                        <div style="margin-bottom: 15px;color: #707070">
                            <span>Main Policy: </span>
                            <span>{{mainPolicyTopicId1}}</span>
                        </div>
                        <div style="margin-bottom: 15px;color: #707070">
                            <span>Topic for synchronization: </span>
                            <span>{{synchronizationTopicId1}}</span>
                        </div>
                        <div style="margin-bottom: 15px;color: #707070">
                            <span>Current Policy: </span>
                            <span>{{instanceTopicId}}</span>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label>Link</mat-label>
                            <input matInput [value]="link1" readonly="true">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="cancel-button">
            <p-button
                    (click)="onOk()"
                    label="Cancel"
                    styleClass="p-button-outlined"
            ></p-button>
        </div>
        <div *ngIf="!exists">
            <p-button (click)="onCreate()" [disabled]="!(valid && initDialog)"
                      [label]="policyType=='Main' ? 'Create' : 'Join'"></p-button>
        </div>
    </div>
</div>
