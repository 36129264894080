<div class="stepper-container" #stepperContainer *ngIf="treeData">
    <div
        (mousedown)="onStartResizeDivider($event)"
        class="stepper-steps-container"
        draggable="false"
    >
        <div class="stepper-steps">
            <app-step-tree
                (currentNodeChange)="onSelectNode($event)"
                [currentNode]="currentNode"
                [treeData]="treeData.children"
            ></app-step-tree>
        </div>
    </div>
    <div class="stepper-view-container">
        <div class="stepper-view-header">
            <!--            <mat-icon class="title-icon">{{currentNode.icon}}</mat-icon>-->
            <div class="title-label">{{ currentNode.name }}</div>
        </div>
        <div class="stepper-view-content">
            <ng-container *ngTemplateOutlet="currentNode.template; context: currentNode"></ng-container>
        </div>
        <!--        <div class="step-action-buttons">-->
        <!--            <button mat-flat-button class="step-action-prev-btn" (click)="onPrevClick()"-->
        <!--                *ngIf="hasPrevStep">Prev</button>-->
        <!--            <button mat-flat-button color="primary" (click)="onNextClick()" *ngIf="hasNextStep">Next</button>-->
        <!--        </div>-->
    </div>
</div>
