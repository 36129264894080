export const idGroupKeywords = [
    'id',
    'uuid',
    'topicId',
    'instanceTopicId',
    'synchronizationTopicId',
    'messageId',
    'accountId',
    'tokenId',
    'idType',
    'inputSchema',
    'presetSchema',
    'outputSchema',
    'schema',
];
export const nameGroupKeywords = [
    'name',
    'description',
    'topicDescription',
    'title',
    'label',
    'optionName',
    'dialogContent',
    'content',
];
export const typeGroupKeywords = [
    'status',
    'type',
    'groupRelationshipType',
    'groupAccessType',
    'blockType',
    'documentType',
    'dataSource',
    'entityType',
    'dataType',
    'input',
    'output',
    'aggregateType',
    'iconType',
    'sortType',
    'dialogType',
    'accountType',
    'typeValue',
    'executionFlow',
    'orderDirection',
    'impactType',
    'period',
    'extention',
];
export const versionGroupKeywords = ['version', 'codeVersion'];
export const userGroupKeywords = [
    'creator',
    'owner',
    'topicOwner',
    'documentSigner',
    'actor',
    'issuer',
];
export const tagGroupKeywords = [
    'policyTag',
    'tag',
    'target',
    'source',
    'bindGroup',
    'bindBlock',
];
export const complexObjGroupKeywords = ['config', 'uiMetaData'];
export const simplePropertiesGroupKeywords = [
    'memoObj',
    'width',
    'topic',
    'expression',
    'value',
    'tooltip',
    'action',
    'url',
    'dialogClass',
    'uiClass',
    'field',
    'periodMask',
    'periodInterval',
    'uiSortFieldPath',
    'nextItemField',
    'uiSortByFieldPath',
    'icon',
    'buttonClass',
    'optionValue',
    'prevDocStatus',
    'rule',
    'fieldPath',
    'condition',
    'variable',
    'role',
    'threshold',
    'sourceField',
    'formula',
    'amount',
    'unit',
    'dialogResultFieldPath',
    'orderField',
];
export const dateGroupKeywords = ['createDate', 'startDate', 'endDate'];
export const arrayGroupKeywords = [
    'policyRoles',
    'policyTopics',
    'policyTokens',
    'policyGroups',
    'members',
    'permissions',
    'roles',
    'groups',
    'presetFields',
    'inputFields',
    'fields',
    'filters',
    'buttons',
    'expressions',
    'variables',
    'dynamicFilters',
    'inputEvents',
    'outputEvents',
    'innerEvents',
    'children',
    'events',
    'artifacts',
    'options',
    'groupByFields',
    'conditions',
    'privateFields',
    'outputFields',
    'equations',
];
export const flagsGroupKeywords = [
    'static',
    'defaultActive',
    'disabled',
    'enableSorting',
    'onlyOwnDocuments',
    'cyclic',
    'readonly',
    'preset',
    'includeAccounts',
    'changeOwner',
    'onlyOwnByGroupDocuments',
    'stopPropagation',
    'visible',
    'canInvite',
    'canDelete',
    'multiple',
    'updatePrevDoc',
    'canBeEmpty',
    'onlyAssignDocuments',
    'onlyAssignByGroupDocuments',
];
export const errorGroupKeywords = ['errorFallbackTag', 'onErrorAction'];
