<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'equationsGroup')"
            [attr.collapse]="propHidden.equationsGroup">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Equations</td>
        <td class="propHeaderCell">
            <div class="propAdd" (click)="addEquation()">
                <mat-icon>add</mat-icon>
                <span>Add Equation</span>
            </div>
        </td>
    </tr>
    <ng-container *ngFor="let equation of properties.equations; let i=index">
        <tr class="propRow" [attr.collapse]="propHidden.equationsGroup">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden.equations, i)" [attr.collapse]="propHidden.equations[i]">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">Equation {{i}}</td>
            <td class="propRowCell">
                {{equation.variable}}
                <span *ngIf="equation.formula"> = {{equation.formula}}</span>
                <span class="remove-prop" [attr.readonly]="readonly" (click)="onRemoveEquation(i)">
                    <mat-icon>delete</mat-icon>
                </span>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.equationsGroup || propHidden.equations[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Variable</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="equation.variable" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.equationsGroup || propHidden.equations[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Formula</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="equation.formula" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>

</table>