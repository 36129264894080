<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Input Documents</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.inputDocuments" [disabled]="readonly" (change)="onSave()">
                <mat-option value="aggregate">Aggregate</mat-option>
                <mat-option value="separate">Separate</mat-option>
            </mat-select>
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'inputSchemaGroup')"
            [attr.collapse]="propHidden.inputSchemaGroup">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propRowCell cellName">Input Schema</td>
        <td class="propRowCell">
            <select-schema
                [schemas]="schemas" 
                [(value)]="properties.inputSchema"
                [disabled]="readonly" 
                (change)="onSelectInput()"
                ></select-schema>
        </td>
    </tr>
    <ng-container *ngIf="properties.inputSchema">
        <tr class="propRow subRow" [attr.collapse]="propHidden.inputSchemaGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName cellTitle">Description</td>
            <td class="propRowCell cellTitle">Alias</td>
        </tr>
    </ng-container>
    <ng-container *ngFor="let field of properties.inputFields; let i=index">
        <tr class="propRow subRow" [attr.collapse]="propHidden.inputSchemaGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">
                <span class="lvl" [attr.lvl]="field.lvl">{{field.title}}</span>
            </td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="field.value" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>

    <tr class="propRow">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'outputSchemaGroup')"
            [attr.collapse]="propHidden.outputSchemaGroup">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propRowCell cellName">Output Schema</td>
        <td class="propRowCell">
            <select-schema
                [schemas]="schemas" 
                [(value)]="properties.outputSchema"
                [disabled]="readonly" 
                (change)="onSelectOutput()"
                ></select-schema>
        </td>
    </tr>
    <ng-container *ngIf="properties.outputSchema">
        <tr class="propRow subRow" [attr.collapse]="propHidden.outputSchemaGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName cellTitle">Description</td>
            <td class="propRowCell cellTitle">Variable</td>
        </tr>
    </ng-container>
    <ng-container *ngFor="let field of properties.outputFields; let i=index">
        <tr class="propRow subRow" [attr.collapse]="propHidden.outputSchemaGroup">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">
                <span class="lvl" [attr.lvl]="field.lvl">{{field.title}}</span>
            </td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="field.value" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>
</table>
