<div class="container" (mousemove)="mousemove($event)" (mouseleave)="mouseleave($event)">
    <div #parent class="canvas">
        <canvas #canvas></canvas>
    </div>
    <div class="drop-container" 
        #treeList="cdkDropList" 
        cdkDropList 
        onDragEntered 
        [cdkDropListDisabled]="readonly"
        [cdkDropListConnectedTo]="menuList" 
        (cdkDropListDropped)="drop($event)"
        (cdkDropListSorted)="onDragSorted($event)" 
        (cdkDropListEntered)="onDragEntered($event)"
        [cdkDropListSortPredicate]="onDragSortPredicate"
    >
        <div *ngIf="data" class="tree" [attr.readonly]="readonly" [attr.move-actions]="visibleMoveActions">
            <div class="block-all-expand" (click)="onAllCollapse()" [attr.collapsed]="allCollapse">
                <mat-icon *ngIf="allCollapse === '2'">unfold_more</mat-icon>
                <mat-icon *ngIf="allCollapse !== '2'">unfold_less</mat-icon>
            </div>

            <ng-container *ngFor="let item of data">

                <div class="block-container" 
                    [attr.expandable]="item.expandable" 
                    [attr.collapsed]="item.collapsed"
                    [attr.selected]="isSelect(item)" 
                    [attr.error]="item.error" 
                    [attr.root]="item.root"
                    [attr.block-id]="item.id" 
                    [attr.block-instance]="item.node.tag" 
                    [attr.block-type]="item.type"
                    [style.paddingLeft]="item.offset" 
                    cdkDrag 
                    [cdkDragData]="item.level" 
                    [cdkDragDisabled]="item.root"
                    [attr.deprecated]="item.deprecated"
                >
                    <div class="block-expand" (click)="onCollapse($event, item)">
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>

                    <div *ngIf="isRootBlockStyle(item)" class="block-body module-body">
                        <div class="block-item" (click)="onSelect($event, item)">
                            <div class="module-item-icon">
                                <mat-icon>article</mat-icon>
                            </div>

                            <div class="module-item-name">
                                Policy
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isRootModuleStyle(item)" class="block-body module-body">
                        <div class="block-item" (click)="onSelect($event, item)">
                            <div class="module-item-icon">
                                <mat-icon class="svg-icon" svgIcon="policy-module"></mat-icon>
                            </div>
                            <div class="module-item-name">
                                Module
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isRootToolStyle(item)" class="block-body module-body">
                        <div class="block-item" (click)="onSelect($event, item)">
                            <div class="module-item-icon">
                                <mat-icon class="tool-icon">handyman</mat-icon>
                            </div>
                            <div class="module-item-name">
                                Tool
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isBlockStyle(item)" class="block-body" [style]="blockStyle(item)">
                        <div class="block-item" (click)="onSelect($event, item)" theme-all>
                            <div class="block-item-icon">
                                <mat-icon theme-text>{{item.icon}}</mat-icon>
                            </div>
                            <div class="block-item-name" theme-text>
                                {{item.node.localTag}}
                            </div>
                        </div>
                        <div class="block-menu">
                            <div class="move-actions-container"
                                [attr.can-up]="item.canUp"
                                [attr.can-down]="item.canDown"
                                [attr.can-left]="item.canLeft"
                                [attr.can-right]="item.canRight"
                            >
                                <button (click)="onDropUp($event)" mat-icon-button 
                                    class="action-button move-action-up">
                                    <mat-icon>arrow_drop_up</mat-icon>
                                </button>
                                <button (click)="onDropLeft($event)" mat-icon-button
                                    class="action-button move-action-left">
                                    <mat-icon>arrow_left</mat-icon>
                                </button>
                                <button (click)="onDropRight($event)" mat-icon-button
                                    class="action-button move-action-right">
                                    <mat-icon>arrow_right</mat-icon>
                                </button>
                                <button (click)="onDropDown($event)" mat-icon-button
                                    class="action-button move-action-down">
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <button (click)="onVisibleMoreActions($event)" mat-icon-button
                                    class="action-button move-more-action-button">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                            </div>
                            <div class="actions-container">
                                <button (click)="onVisibleMoreActions($event)" mat-icon-button
                                        class="action-button more-action-button">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <button (click)="onSearch($event)" mat-icon-button class="action-button">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button (click)="onDelete($event)" mat-icon-button class="action-button delete-action">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isModuleStyle(item)" class="block-body module-body">
                        <div class="block-item" (click)="onSelect($event, item)" (dblclick)="onOpen($event, item)">
                            <div class="module-item-icon">
                                <mat-icon class="svg-icon" svgIcon="policy-module"></mat-icon>
                            </div>
                            <div class="module-item-name">
                                {{item.node.localTag}}
                            </div>
                        </div>
                        <div class="block-menu module-menu">
                            <div class="move-actions-container"
                                [attr.can-up]="item.canUp"
                                [attr.can-down]="item.canDown"
                                [attr.can-left]="item.canLeft"
                                [attr.can-right]="item.canRight"
                            >
                                <button (click)="onDropUp($event)" mat-icon-button 
                                    class="action-button move-action-up">
                                    <mat-icon>arrow_drop_up</mat-icon>
                                </button>
                                <button (click)="onDropLeft($event)" mat-icon-button
                                    class="action-button move-action-left">
                                    <mat-icon>arrow_left</mat-icon>
                                </button>
                                <button (click)="onDropRight($event)" mat-icon-button
                                    class="action-button move-action-right">
                                    <mat-icon>arrow_right</mat-icon>
                                </button>
                                <button (click)="onDropDown($event)" mat-icon-button
                                    class="action-button move-action-down">
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <button (click)="onVisibleMoreActions($event)" mat-icon-button
                                    class="action-button move-more-action-button">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                            </div>
                            <div class="actions-container">
                                <button (click)="onVisibleMoreActions($event)" mat-icon-button
                                    class="action-button more-action-button">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <button (click)="onDelete($event)" mat-icon-button class="action-button delete-action">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isToolStyle(item)" class="block-body tool-body">
                        <div class="block-item" (click)="onSelect($event, item)">
                            <div class="module-item-icon">
                                <mat-icon class="tool-icon">handyman</mat-icon>
                            </div>
                            <div class="module-item-name">
                                {{item.node.localTag}}
                            </div>
                        </div>
                        <div class="block-menu module-menu">
                            <div class="move-actions-container"
                                [attr.can-up]="item.canUp"
                                [attr.can-down]="item.canDown"
                                [attr.can-left]="item.canLeft"
                                [attr.can-right]="item.canRight"
                            >
                                <button (click)="onDropUp($event)" mat-icon-button 
                                    class="action-button move-action-up">
                                    <mat-icon>arrow_drop_up</mat-icon>
                                </button>
                                <button (click)="onDropLeft($event)" mat-icon-button
                                    class="action-button move-action-left">
                                    <mat-icon>arrow_left</mat-icon>
                                </button>
                                <button (click)="onDropRight($event)" mat-icon-button
                                    class="action-button move-action-right">
                                    <mat-icon>arrow_right</mat-icon>
                                </button>
                                <button (click)="onDropDown($event)" mat-icon-button
                                    class="action-button move-action-down">
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <button (click)="onVisibleMoreActions($event)" mat-icon-button
                                    class="action-button move-more-action-button">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                            </div>
                            <div class="actions-container">
                                <button (click)="onVisibleMoreActions($event)" mat-icon-button
                                    class="action-button more-action-button">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <button (click)="onDelete($event)" mat-icon-button class="action-button delete-action">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                <div 
                    *ngIf="isSuggenstionEnabled && isSelect(item) && nestedBlock"
                    class="block-container preview" 
                    [attr.expandable]="false" 
                    [attr.selected]="false"
                    [attr.block-type]="nestedBlock.type" 
                    [style.paddingLeft]="getNestedOffset(item.level+1)"
                    (click)="nested.emit(nestedBlock.type)">
                    <div class="block-expand">
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>
                    <div class="block-body" [style]="blockStyle(nestedBlock)"
                        [matTooltip]="blockTypeTips[nestedBlock.type]" matTooltipPosition="above"
                        matTooltipClass="guardian-tooltip">
                        <div class="block-item" theme-all>
                            <div class="block-item-icon">
                                <mat-icon theme-text>{{nestedBlock.icon}}</mat-icon>
                            </div>
                            <div class="block-item-name" theme-text>
                                {{nestedBlock.name}}
                            </div>
                        </div>
                    </div>
                </div>

                <div 
                    *ngIf="isSuggenstionEnabled && nextBlock && isLatestBlockInSelect(item)"
                    (click)="next.emit(nextBlock.type)"
                    class="block-container preview"
                    [attr.expandable]="false" 
                    [attr.selected]="false" 
                    [attr.block-type]="nextBlock.type"
                    [style.paddingLeft]="selectedNode?.offset">
                    <div class="block-expand">
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>
                    <div class="block-body" [style]="blockStyle(nextBlock)" [matTooltip]="blockTypeTips[nextBlock.type]"
                        matTooltipPosition="below" matTooltipClass="guardian-tooltip">
                        <div class="block-item" theme-all>
                            <div class="block-item-icon">
                                <mat-icon theme-text>{{nextBlock.icon}}</mat-icon>
                            </div>
                            <div class="block-item-name" theme-text>
                                {{nextBlock.name}}
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>
    </div>
    <div #tooltip class="tooltip"></div>
</div>
