<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>
<div *ngIf="history" class="container" [attr.readonly]="loading">
    <div
        *ngIf="history.top"
        (click)="onOpen()"
        [attr.count]="history.count"
        class="main-tags"
    >
        <div
            [attr.count]="history.top.count"
            [attr.owner]="history.top.owner"
            class="main-tag"
        >
            <mat-icon class="tag-icon">sell</mat-icon>
            <div class="tag-name">
                {{ history.top.name }}
            </div>
            <div class="tag-count">
                {{ history.top.count }}
            </div>
        </div>
        <mat-icon class="edit-tags">more_horiz</mat-icon>
    </div>
    <div 
        *ngIf="compact"
        (click)="onAdd()" 
    >
        <button class="p-button-outlined">
            <svg-icon class="svg-icon-16" 
                src="/assets/images/icons/16/add.svg" 
                svgClass="primary-color"></svg-icon>
            Create a Tag
        </button>
    </div>
</div>
