<form>
    <ng-container *ngFor="let item of fields">
        <div *ngIf="!item.hide" class="form-field-container">
            <div *ngIf="!item.isArray && !item.isRef" class="form-field">
                <div class="label-field">{{item.description}}</div>

                <div class="form-field-value">
                    <div class="form-field-prefix" *ngIf="isPrefix(item)">{{item.unit}}</div>
                    <div *ngIf="isInput(item)" class="example-full-width">
                        <input [ngClass]="{'hidden-item': isIPFS(item) }" [readonly]="true" [value]="item.value" class="p-field-input"
                               pInputText>
                        <div *ngIf="isIPFS(item)">
                            <div class="image-action-buttons">
                                <button [cdkCopyToClipboard]="item.value" class="button" pButton type="button">
                                    Copy Link
                                </button>
                                <button [cdkCopyToClipboard]="getCID(item.value)" class="button secondary" pButton>
                                    Copy CID
                                </button>
                            </div>
                            <img *ngIf="!item.loading; else loading" class="ipfs-image" [src]="item.imgSrc"
                                [alt]="item.value">
                            <ng-template #loading>
                                <mat-spinner class="loading"></mat-spinner>
                            </ng-template>
                        </div>
                    </div>

                    <div *ngIf="isTime(item)" class="example-full-width">
                        <input [readonly]="true" [value]="item.value | date: 'mediumTime'" class="p-field-input" pInputText
                               type="date">
                    </div>

                    <div *ngIf="isDateTime(item)" class="example-full-width">
                        <input [readonly]="true" [value]="item.value | date: 'medium'" class="p-field-input" pInputText
                               type="date">
                    </div>

                    <div *ngIf="isDate(item)" appearance="outline" class="example-full-width">
                        <input [readonly]="true" [value]="item.value" class="p-field-input" pInputText
                               type="date">
                    </div>

                    <div *ngIf="isBoolean(item)" class="boolean-form-field">
                        <span class="boolean-show">{{ isBooleanView(item.value) }}</span>
                        <!--                        <p-checkbox *ngIf="!isUnset(item.value)" [(ngModel)]="item.value" [name]="item.name" [binary]="true"></p-checkbox>-->
                        <!--                        <span *ngIf="isUnset(item.value)">Unset</span>-->
                    </div>

                    <div class="form-field-postfix" *ngIf="isPostfix(item)">{{item.unit}}</div>
                </div>
            </div>

            <div *ngIf="!item.isArray && item.isRef" class="form-field-group">
                <div class="label-field">{{item.description}}</div>
                <div *ngIf="this.values && this.values[item.name]" class="form-field-array-item">
                    <div class="form-field-group">
                        <ng-container [ngSwitch]="item.customType">
                            <app-geojson-type *ngSwitchCase="'geo'"
                                [preset]="this.values && this.values[item.name]" [disabled]="true">
                            </app-geojson-type>
                            <app-schema-form-view *ngSwitchDefault [fields]="item.fields" [private-fields]="hide"
                                                  [delimiter-hide]="true"
                                                  [dryRun]="dryRun" [values]="this.values && this.values[item.name]">
                            </app-schema-form-view>
                        </ng-container>
                    </div>
                </div>
            </div>


            <div *ngIf="item.isArray && !item.isRef" class="form-field-array">
                <div class="label-field">{{item.description}}</div>
                <div *ngIf="item.isInvalidType" class="invalid-type">
                    <b>The field type does not match the field type in the schema</b>
                </div>
                <div *ngFor="let listItem of getItemsPage(item, item.pageEvent)" class="form-field-array-item"
                    [attr.index]="item.list.indexOf(listItem)">
                    <div class="form-field">

                        <div class="form-field-value">
                            <div class="form-field-prefix" *ngIf="isPrefix(item)">{{item.unit}}</div>
                            <div *ngIf="isInput(item)" class="example-full-width">
                                <input [ngClass]="{'hidden-item': isIPFS(item) }" [readonly]="true" [value]="listItem.value" class="p-field-input"
                                       pInputText>
                                <div *ngIf="isIPFS(item)">
                                    <div class="image-action-buttons">
                                        <button [cdkCopyToClipboard]="listItem.value" class="button" pButton type="button">
                                            Copy Link
                                        </button>
                                        <button [cdkCopyToClipboard]="getCID(listItem.value)" class="button secondary"
                                                pButton>
                                            Copy CID
                                        </button>
                                    </div>
                                    <img *ngIf="!listItem.loading; else loading" [alt]="listItem.value" [src]="listItem.imgSrc"
                                         class="ipfs-image">
                                    <ng-template #loading>
                                        <mat-spinner class="loading"></mat-spinner>
                                    </ng-template>
                                </div>
                            </div>

                            <div *ngIf="isTime(item)" class="example-full-width">
                                <input [readonly]="true" [value]="listItem.value | date: 'mediumTime'" class="p-field-input" pInputText
                                       type="date">
                            </div>

                            <div *ngIf="isDateTime(item)" class="example-full-width">
                                <input [readonly]="true" [value]="listItem.value | date: 'medium'" class="p-field-input" pInputText
                                       type="date">
                            </div>

                            <div *ngIf="isDate(item)" appearance="outline" class="example-full-width">
                                <input [readonly]="true" [value]="listItem.value" class="p-field-input" pInputText
                                       type="date">
                            </div>

                            <div *ngIf="isBoolean(item)" class="boolean-form-field">
                                <p-checkbox [(ngModel)]="listItem.value" name="groupname" value="val1"></p-checkbox>
                            </div>

                            <div class="form-field-postfix" *ngIf="isPostfix(item)">{{item.unit}}</div>
                        </div>
                    </div>
                </div>

                <mat-paginator *ngIf="pageSize < item.list.length" [length]="item.list.length"
                    [pageSizeOptions]="[pageSize]" (page)="item.pageEvent = $event">
                </mat-paginator>
            </div>


            <div *ngIf="item.isArray && item.isRef" class="form-field-group schema-form-field-array-group">
                <div class="label-field">{{item.description}}</div>
                <div *ngFor="let listItem of getItemsPage(item, item.pageEvent)" class="form-field-array-item"
                    [attr.index]="item.list.indexOf(listItem)">
                    <div class="form-field-group">
                        <ng-container [ngSwitch]="item.customType">
                            <app-geojson-type *ngSwitchCase="'geo'" [preset]="listItem" [disabled]="true">
                            </app-geojson-type>
                            <app-schema-form-view *ngSwitchDefault [fields]="item.fields" [private-fields]="hide"
                                                  [delimiter-hide]="true" [dryRun]="dryRun" [values]="listItem">
                            </app-schema-form-view>
                        </ng-container>
                    </div>
                    <div class="short-delimiter-container">
                        <div class="delimiter"></div>
                    </div>
                </div>

                <mat-paginator *ngIf="pageSize < item.list.length" [length]="item.list.length"
                    [pageSizeOptions]="[pageSize]" (page)="item.pageEvent = $event">
                </mat-paginator>
            </div>

        </div>

        <div *ngIf="!delimiterHide" class="delimiter"></div>

    </ng-container>
</form>
