<div class="content">
    <h1 mat-dialog-title>
        {{title}}
    </h1>
    <div mat-dialog-content>
        <form [formGroup]="dataForm">
            <mat-form-field *ngFor="let field of fieldsConfig" appearance="outline">
                <mat-label>
                    <span *ngIf="field.required">*</span>
                    {{field.label}}
                </mat-label>
                <input matInput type="text" placeholder={{field.placeholder}} [formControlName]="field.name">
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-raised-button color="primary" [mat-dialog-close]="dataForm.value"
                    [disabled]="!dataForm.valid">Ok</button>
                <button mat-button type="button" (click)="onNoClick()">Cancel</button>
            </div>
        </form>
    </div>

    <div *ngIf="loading" class="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>