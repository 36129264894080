<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Update previous document status</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.uiMetaData.updatePrevDoc"
                [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Status value</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.uiMetaData.prevDocStatus" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>
</table>