<app-dialog-wrapper (cancel)="onNoClick()" [confirmVisible]="false" [syncVisible]="true" [syncDate]="syncDate"
    (sync)="sync($event)" [loading]="loading" [title]="'Pools'" [length]="length" [pageSize]="pageSize"
    (page)="onPage($event)">

    <div class="action-buttons">
        <mat-form-field appearance="outline">
            <mat-label>Tokens</mat-label>
            <mat-select [(value)]="selectedTokens" multiple (selectionChange)="loadPools()">
                <mat-option *ngFor="let token of tokens" [value]="token.tokenId">{{token.tokenSymbol}}
                    ({{token.tokenId}})</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="pools?.length; else noPools">
        <div *ngFor="let pool of pools" class="pool">
            <div class="tokens">
                <div class="token-card" *ngFor="let token of pool.tokens">
                    <span class="token-card__token">
                        <mat-icon>abc</mat-icon>
                        <span>{{token.tokenSymbol}}</span>
                    </span>
                    <span class="token-card__token">
                        <mat-icon>generating_tokens</mat-icon>
                        <span>{{token.token}}</span>
                    </span>
                    <span class="token-card__count">
                        <mat-icon>calculate</mat-icon>
                        <span>{{token.count | tokenCount: token.decimals }}</span>
                    </span>
                </div>
            </div>
            <div class="status">
                <div class="status__label" [attr.enabled]="pool.enabled">{{pool.enabled ? 'Enabled' : 'Not enabled'}}
                </div>
            </div>
            <div class="approve">
                <span class="approve__label" [attr.approve]="!pool.immediately">{{ pool.immediately ? 'Without approve':
                    'Need approve' }}</span>
            </div>
            <div class="pool-actions">
                <button class="btn--remove" mat-icon-button [disabled]="!hasPermissions(contract.permissions, 1)"
                    matTooltip="You are not admin" matTooltipClass="guardian-tooltip"
                    [matTooltipDisabled]="hasPermissions(contract.permissions, 1)"
                    (click)="removePool(pool)"><mat-icon>delete</mat-icon></button>
            </div>
        </div>
    </div>
</app-dialog-wrapper>
<ng-template #noPools>
    <div class="no-pools">Contract has no pools, try to sync.</div>
</ng-template>