<div class="content login">
    <div class="overlay"></div>
    <form class="credential-creation" [formGroup]="loginForm" (ngSubmit)="signup()" *ngIf="isRoleSelected$ | async; else selectRoleTemplate">
        <div class="login-header">
            <button class="login-header__back-button" (click)="setRole('')">
                <mat-icon>chevron_left</mat-icon>
                <span>Back</span>
            </button>
            <span class="login-header__title">Create Account</span>
        </div>

        <div class="login-content">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Username:</mat-label>
                <input matInput formControlName="login" (input)="onInput()">

                <span *ngIf="error" class="field-error">{{error}}</span>
                <span *ngIf="shouldShowRequiredError('login')" class="field-error">
                    Value is required
                </span>
            </mat-form-field>

            <div class="password-control">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Password:</mat-label>
                    <input matInput formControlName="password" [type]="passFieldType">

                    <span *ngIf="shouldShowRequiredError('password')" class="field-error">
                        Value is required
                    </span>
                </mat-form-field>

                <mat-icon
                    *ngIf="showPasswordValue; else hidePassBtn"
                    class="eye-icon"
                    (click)="togglePasswordShow()"
                >
                    visibility_on
                </mat-icon>

                <ng-template #hidePassBtn>
                    <mat-icon class="eye-icon" (click)="togglePasswordShow()">visibility_off</mat-icon>
                </ng-template>
            </div>

            <div class="password-control">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Confirm Password:</mat-label>
                    <input matInput formControlName="confirmPassword" [type]="confirmPassFieldType">

                    <span *ngIf="shouldShowRequiredError('confirmPassword')" class="field-error">
                        Value is required
                    </span>
                    <span *ngIf="showPassMismatchError" class="field-error">
                        The entered passwords do not match
                    </span>
                </mat-form-field>

                <mat-icon
                    *ngIf="showConfirmPasswordValue; else hideConfirmPassBtn"
                    class="eye-icon"
                    (click)="toggleConfirmPasswordShow()"
                >
                    visibility_on
                </mat-icon>

                <ng-template #hideConfirmPassBtn>
                    <mat-icon class="eye-icon" (click)="toggleConfirmPasswordShow()">visibility_off</mat-icon>
                </ng-template>
            </div>
        </div>

        <button mat-raised-button type="submit" color="primary" [disabled]="!loginForm.valid">Create</button>
    </form>

    <ng-template #selectRoleTemplate>
        <div class="role-select-step">
            <span class="select-role-title">Select Account Type</span>
            <button mat-button (click)="setRole('STANDARD_REGISTRY')">
                <span class="role-name">Standard Registry</span>
                <span class="role-description">Creating/importing policies, managing users, managing tokens.</span>
            </button>
            <button mat-button (click)="setRole('USER')">
                <span class="role-name">User</span>
                <span class="role-description">Applicants creating environmental projects / performing other<br>activities in the environmental domain.</span>
            </button>
            <button mat-button (click)="setRole('AUDITOR')">
                <span class="role-name">Auditor</span>
                <span class="role-description">Read only access (only relevant to iREC Policies).</span>
            </button>

            <button mat-icon-button class="close-button" type="button" (click)="goBack()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </ng-template>

    <div *ngIf="loading" class="loading">
        <div class="preloader-image preloader-image-l-size"></div>
    </div>
</div>
