<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'options')" [attr.collapse]="propHidden.options">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Options</td>
        <td class="propHeaderCell"></td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">URL</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.url" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Method</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.method" [disabled]="readonly" (change)="onSave()">
                <mat-option value="GET">GET</mat-option>
                <mat-option value="POST">POST</mat-option>
                <mat-option value="PUT">PUT</mat-option>
                <mat-option value="PATCH">PATCH</mat-option>
                <mat-option value="DELETE">DELETE</mat-option>
            </mat-select>
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Body</td>
        <td class="propRowCell">
            <input class="prop-input" type="text" [(ngModel)]="properties.messageBody" [readonly]="true"
                (click)="editBody($event)" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'conditionsGroup')"
            [attr.collapse]="propHidden.options || propHidden.conditionsGroup">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Headers</td>
        <td class="propHeaderCell">
            <div class="propAdd" (click)="addHeader()">
                <mat-icon>add</mat-icon>
                <span>Add header</span>
            </div>
        </td>
    </tr>
    <ng-container *ngFor="let header of properties.headers; let i=index">
        <tr class="propRow" [attr.collapse]="propHidden.options || propHidden.conditionsGroup">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden.conditions, i)"
                [attr.collapse]="propHidden.conditions[i]">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">Header {{i}}</td>
            <td class="propRowCell">
                <span class="remove-prop" [attr.readonly]="readonly" (click)="onRemoveHeader(i)">
                    <mat-icon>delete</mat-icon>
                </span>
            </td>
        </tr>
        <tr class="propRow subRow"
            [attr.collapse]="propHidden.options || propHidden.conditionsGroup || propHidden.conditions[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Header name</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="header.name" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow"
            [attr.collapse]="propHidden.options || propHidden.conditionsGroup || propHidden.conditions[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Header value</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="header.value" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>
</table>