<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'options')" [attr.collapse]="propHidden.options">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Options</td>
        <td class="propHeaderCell"></td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Aggregate Type</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.aggregateType" [disabled]="readonly">
                <mat-option value="period">Period</mat-option>
                <mat-option value="cumulative">Cumulative Dimension</mat-option>
            </mat-select>
        </td>
    </tr>
    <ng-container *ngIf="properties.aggregateType == 'period'">
        <tr class="propRow" [attr.collapse]="propHidden.options">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Empty Data</td>
            <td class="propRowCell">
                <input class="prop-input" type="checkbox" [(ngModel)]="properties.emptyData" [readonly]="readonly"
                    (blur)="onSave()">
            </td>
        </tr>
    </ng-container>

    <ng-container *ngIf="properties.aggregateType == 'cumulative'">
        <tr class="propHeader">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'expressionsGroup')"
                [attr.collapse]="propHidden.options || propHidden.expressionsGroup">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propHeaderCell cellName">Expressions</td>
            <td class="propHeaderCell">
                <div class="propAdd" (click)="addExpression()">
                    <mat-icon>add</mat-icon>
                    <span>Add Expression</span>
                </div>
            </td>
        </tr>
        <ng-container *ngFor="let expression of properties.expressions; let i=index">
            <tr class="propRow" [attr.collapse]="propHidden.options || propHidden.expressionsGroup">
                <td class="propRowCol cellCollapse" (click)="onHide(propHidden.expressions, i)"
                    [attr.collapse]="propHidden.expressions[i]">
                    <mat-icon>arrow_drop_down</mat-icon>
                </td>
                <td class="propRowCell cellName">Expression {{i}}</td>
                <td class="propRowCell">
                    <span>{{expression.name}} = {{expression.value}}</span>
                    <span class="remove-prop" [attr.readonly]="readonly" (click)="onRemoveExpression(i)">
                        <mat-icon>delete</mat-icon>
                    </span>
                </td>
            </tr>
            <tr class="propRow subRow"
                [attr.collapse]="propHidden.options || propHidden.expressionsGroup || propHidden.expressions[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">Variable Name</td>
                <td class="propRowCell">
                    <input class="prop-input" [(ngModel)]="expression.name" [readonly]="readonly" (blur)="onSave()">
                </td>
            </tr>
            <tr class="propRow subRow"
                [attr.collapse]="propHidden.options || propHidden.expressionsGroup || propHidden.expressions[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">Variable Value</td>
                <td class="propRowCell">
                    <input class="prop-input" [(ngModel)]="expression.value" [readonly]="readonly" (blur)="onSave()">
                </td>
            </tr>
        </ng-container>

        <tr class="propRow" [attr.collapse]="propHidden.main" [attr.collapse]="propHidden.options">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Condition</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="properties.condition" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>
</table>