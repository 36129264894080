<div>
    <form [formGroup]="dataForm">
        <div class="form-input-container">
            <label class="p-field-label">* Name</label>
            <input class="p-field-input" formControlName="name" pInputText type="text"/>
        </div>
        <div class="form-input-container">
            <label class="p-field-label">Description</label>
            <input class="p-field-input" formControlName="description" pInputText type="text"/>
        </div>
    </form>

    <div *ngIf="schemas && schemas.length" class="artifact-container">
        <p-dropdown *ngIf="canAddDocument" [(ngModel)]="schema" [options]="schemas" optionLabel="name">
            <ng-template pTemplate="selectedItem">
                <div *ngIf="schema" class="country-item country-item-value">
                    <div>{{ schema.name }}</div>
                </div>
            </ng-template>
            <ng-template let-schema pTemplate="item">
                <div class="country-item">
                    <div>{{ schema.name }}</div>
                </div>
            </ng-template>
        </p-dropdown>

        <div *ngIf="schema" class="artifact-form">
            <div class="artifact-form-label">
                {{ schema.name }}
            </div>
            <div (click)="onDeleteArtifact()" class="delete-artifact">
                <mat-icon>delete</mat-icon>
            </div>
            <form [formGroup]="schemaForm">
                <app-schema-form [formGroup]="schemaForm" [schema]="schema" [showButtons]="false"></app-schema-form>
            </form>
        </div>
    </div>
    <div class="dialog-footer">
        <button (click)="onAddArtifact(schema)" *ngIf="!schema" class="button secondary" label="Add Document"
                pButton style="margin-right: auto;"></button>
        <button (click)="onNoClick()" class="button secondary" label="Cancel" pButton></button>
        <button (click)="onCreate()" [disabled]="disabled" [label]="'Create'" class="button" pButton></button>
    </div>
</div>

<div *ngIf="false" class="g-dialog-content">
    <div class="g-dialog-header">
        <div class="g-dialog-cancel">
            <div class="g-dialog-cancel-btn" (click)="onNoClick()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <div class="g-dialog-title">
            {{title}}
        </div>
        <div class="g-dialog-actions">
            <div class="g-dialog-actions-btn" (click)="onCreate()" [attr.disabled]="disabled">
                Create
            </div>
        </div>
    </div>
    <div class="g-dialog-body">
        <div style="padding: 20px 35px 30px 35px">
            <form [formGroup]="dataForm">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>* Label</mat-label>
                    <input matInput type="text" placeholder="Label" formControlName="name">
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput type="text" placeholder="Description" formControlName="description">
                </mat-form-field>
            </form>

            <div *ngIf="schemas && schemas.length" class="artifact-container">
                <div *ngIf="!schema" class="add-artifact" (click)="onAddArtifact(selector)">
                    <mat-icon class="add-icon">add</mat-icon>
                    <div class="btn-name">
                        Add Document
                    </div>
                </div>

                <mat-select #selector class="artifact-selector" [(value)]="schema" (selectionChange)="onSelectSchema()">
                    <mat-option *ngFor="let schema of schemas" [value]="schema">
                        <span>{{schema.name}}</span>
                    </mat-option>
                </mat-select>

                <!-- <div *ngIf="schema" class="selected-artifact">
                    <div class="selected-artifact-name">
                        <span class="selected-artifact-label">Artifact</span>
                        <span>{{schema.name}}</span>
                    </div>
                    <div class="delete-artifact" (click)="onDeleteArtifact()">
                        <mat-icon>delete</mat-icon>
                    </div>
                </div> -->

                <div *ngIf="schema" class="artifact-form">
                    <div class="artifact-form-label">
                        {{schema.name}}
                    </div>
                    <div class="delete-artifact" (click)="onDeleteArtifact()">
                        <mat-icon>delete</mat-icon>
                    </div>
                    <form [formGroup]="schemaForm">
                        <app-schema-form [formGroup]="schemaForm" [schema]="schema" [showButtons]="false"></app-schema-form>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
