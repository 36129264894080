<div class="content" [hidden]="!isActive">
    <ng-container *ngIf="type != 'tabs'">
        <div *ngFor="let block of blocks" class="block-item">
            <render-block [block]="block" [dryRun]="dryRun" [policyId]="policyId"></render-block>
        </div>
    </ng-container>

    <ng-container *ngIf="type == 'tabs'">
        <div *ngIf="blocks && canDisplayTabs()" class="header">
            <mat-tab-group (selectedIndexChange)="onBlockChange($event)" [selectedIndex]="selectedIndex">
                <mat-tab *ngFor="let block of blocks" [label]="getTitle(block)"></mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="activeBlock" class="body">
            <render-block [block]="activeBlock" [dryRun]="dryRun" [policyId]="policyId"></render-block>
        </div>
    </ng-container>
</div>
