<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
    <div class="content">
        <div class="json">
            {{json}}
        </div>
        <div mat-dialog-actions>
            <button mat-button type="button" (click)="onClick()">Cancel</button>
        </div>
    </div>
</div>