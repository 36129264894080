<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Output Schema</td>
        <td class="propRowCell">
            <select-schema
                [schemas]="schemas" 
                [(value)]="properties.outputSchema"
                [disabled]="readonly" 
                (change)="onSave()"
                ></select-schema>
        </td>
    </tr>

    <tr class="propRow" [attr.readonly]="readonly" *ngIf="properties">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Document Signer</td>
        <td class="propRowCell remove-cell">
            <mat-select [(ngModel)]="properties.documentSigner" [disabled]="readonly" (change)="onSave()">
                <mat-option value="">Policy Owner</mat-option>
                <mat-option value="owner">First Document Owner</mat-option>
                <mat-option value="issuer">First Document Issuer</mat-option>
            </mat-select>
        </td>
    </tr>

    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Id Type</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.idType" [disabled]="readonly" (change)="onSave()">
                <mat-option value=""></mat-option>
                <mat-option value="DID">DID (New DID)</mat-option>
                <mat-option value="UUID">UUID (New UUID)</mat-option>
                <mat-option value="OWNER">Owner (Owner DID)</mat-option>
                <mat-option value="DOCUMENT">From First Document Id</mat-option>
            </mat-select>
        </td>
    </tr>

    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Expression</td>
        <td class="propRowCell">
            <input class="prop-input" type="text" [(ngModel)]="properties.expression" [readonly]="true"
                (click)="editExpression($event)">
        </td>
    </tr>
</table>
