<div class="container">
    <ng-container [ngTemplateOutlet]="header"></ng-container>
    <ng-container *ngIf="!isLoading">
        <ng-container [ngTemplateOutlet]="container"></ng-container>
    </ng-container>
    <div *ngIf="isLoading" class="loading">
        <div class="preloader-image preloader-image-l-size"></div>
    </div>
</div>
<ng-container [ngTemplateOutlet]="actionsContainer"></ng-container>

<ng-template #header>
    <div class="header">
        <h1>Settings</h1>
    </div>
</ng-template>

<ng-template #container>
    <div class="settings-container">
        <form [formGroup]="dataForm">
            <div class="form-input-container">
                <label class="p-field-label" for="operatorId">* Operator ID</label>
                <input class="p-field-input" formControlName="operatorId" id="operatorId" pInputText placeholder="0.0.1159"
                       type="text"/>
            </div>
            <div class="form-input-container">
                <label class="p-field-label" for="opKey">* Operator Key (Hedera Account Private Key)</label>
                <input class="p-field-input" formControlName="operatorKey" id="opKey" pInputText placeholder="Operator key"
                       type="text"/>
            </div>
            <div class="form-input-container">
                <label class="p-field-label" for="ipfsKey">* IPFS Storage API Key</label>
                <input [formControl]="getFormControl(keyAndProof,'key')" class="p-field-input" id="ipfsKey"
                       pInputText placeholder="IPFS Storage API Key"
                       type="text"/>
            </div>
            <div class="form-input-container">
                <label class="p-field-label" for="ipfsKey">* IPFS Storage API proof</label>
                <input [formControl]="getFormControl(keyAndProof,'proof')" class="p-field-input" id="ipfsProof"
                       pInputText placeholder="IPFS Storage API Key"
                       type="text"/>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #actionsContainer>
    <div class="actions-container" id="fixed-actions-container">
        <div class="right-buttons">
            <button (click)="onSubmit()" [disabled]="dataForm.invalid" class="button" pButton>
                <span>Save Changes</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #spinner>
    <mat-spinner class="loading"></mat-spinner>
</ng-template>
