<div class="center-container">
    <div class="task-viewer">
        <div class="header">{{ action }}...</div>
        <div class="progress-bar">
            <p-progressBar [showValue]="false" [value]="progressValue"></p-progressBar>
        </div>
        <div class="status-items">
            <ng-container *ngFor="let status of statuses">
                <div *ngIf="isInfo(status)" class="info-part">INFO: {{ status.message }}</div>
                <div *ngIf="!isInfo(status)" class="non-info-part">
                    {{ status.message }} <span>{{ status.type }}</span></div>
            </ng-container>
        </div>
    </div>
</div>
