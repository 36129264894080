<div class="content">
    <div *ngIf="loading" class="loading">
        <mat-spinner></mat-spinner>
    </div>

    <ng-container *ngIf="!isConfirmed">
        <div class="not-exist">
            Before starting work you need to get DID <a [routerLink]="['/profile']">here</a>
        </div>
    </ng-container>

    <ng-container *ngIf="isConfirmed">
        <div class="actions">
            <div style="text-align: end">
                <button mat-raised-button color="primary" class="toolbar-btn add" (click)="openRetirePoolsDialog()">
                    <mat-icon>visibility</mat-icon>
                    <span>Retire</span>
                </button>
                <button (click)="openRetireRequestsDialog()" class="toolbar-btn" color="primary"
                        mat-raised-button>
                    <mat-icon>visibility</mat-icon>
                    <span>Requests</span>
                </button>
            </div>
        </div>
        <div class="table-container">
            <ng-container>
                <table mat-table [dataSource]="contractRequests" class="retire-table">
                    <ng-container matColumnDef="contractId">
                        <th mat-header-cell *matHeaderCellDef> Contract Id </th>
                        <td mat-cell *matCellDef="let element">
                            <hedera-explorer type="accounts"
                                [params]="element.document.credentialSubject[0].contractId">{{element.document.credentialSubject[0].contractId}}</hedera-explorer>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> Date </th>
                        <td mat-cell *matCellDef="let element">
                            {{ getDate(element.document.issuanceDate)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="operation">
                        <th mat-header-cell *matHeaderCellDef> Operations </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="view-action-btn"
                                (click)="viewRetireRequest(element)">
                                <span>View Details</span>
                                <mat-icon>visibility</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsContractRequests"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsContractRequests;"></tr>
                </table>

            </ng-container>
        </div>
        <div class="table-paginator">
            <mat-paginator [length]="contractsCount" [pageIndex]="pageIndex" [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50, 100]" (page)="onPage($event)" aria-label="Select page">
            </mat-paginator>
        </div>
    </ng-container>
</div>
