<div class="content" [hidden]="!isActive">
    <div *ngIf="!selected">
        <div *ngIf="groups" class="table-container">
            <table mat-table [dataSource]="groups" class="table">
                <ng-container matColumnDef="id">
                    <th *matHeaderCellDef mat-header-cell>Group Id</th>
                    <td mat-cell *matCellDef="let element">
                        <div (click)="onSelect(element)" class="select-btn">
                            {{ element.id }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="groupLabel">
                    <th *matHeaderCellDef mat-header-cell>Label</th>
                    <td *matCellDef="let element" mat-cell>
                        {{ element.groupLabel }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="groupName">
                    <th *matHeaderCellDef mat-header-cell>Group Name</th>
                    <td *matCellDef="let element" mat-cell>
                        {{ element.groupName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th *matHeaderCellDef mat-header-cell>Role</th>
                    <td *matCellDef="let element" mat-cell>
                        {{ element.role }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th *matHeaderCellDef mat-header-cell>Type</th>
                    <td *matCellDef="let element" mat-cell>
                        {{ element.type }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td mat-cell *matCellDef="let element">
                        <p-button
                            (click)="onInvite(element)"
                            *ngIf="element.canInvite"
                            label="Get Invite"
                        >
                        </p-button>
                    </td>
                </ng-container>
                <tr
                        *matHeaderRowDef="groupColumns; sticky: true"
                        mat-header-row
                ></tr>
                <tr *matRowDef="let row; columns: groupColumns" mat-row></tr>
            </table>
        </div>
    </div>

    <div *ngIf="selected">
        <div class="actions">
            <div class="role">
                <div (click)="onBack()" class="link">
                    <mat-icon>chevron_left</mat-icon>
                    Back
                </div>
            </div>
            <div class="role">
                <span>Role: </span>
                <span>{{ role }}</span>
            </div>
            <div class="role">
                <span>Type: </span>
                <span>{{ type }}</span>
            </div>
            <div class="role">
                <span>Relationship Type: </span>
                <span>{{ groupRelationshipType }}</span>
            </div>
            <div class="role" style="flex: 1;">
                <span>Access Type: </span>
                <span>{{ groupAccessType }}</span>
            </div>
            <p-button
                (click)="onInvite(selected)"
                *ngIf="canInvite"
                label="Get Invite"
            >
            </p-button>
        </div>
        <div *ngIf="users" class="table-container">
            <table mat-table [dataSource]="users" class="table">
                <ng-container matColumnDef="username">
                    <th *matHeaderCellDef mat-header-cell>User Name</th>
                    <td *matCellDef="let element" mat-cell>
                        {{ element.username }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th *matHeaderCellDef mat-header-cell>Role</th>
                    <td *matCellDef="let element" mat-cell>
                        {{ element.role }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th *matHeaderCellDef mat-header-cell>Type</th>
                    <td *matCellDef="let element" mat-cell>
                        {{ element.type }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td mat-cell *matCellDef="let element">
                        <div
                            (click)="onDelete(element)"
                            *ngIf="canDelete && element.type !== 'Owner'"
                            class="btn-settings btn-delete"
                        >
                            <mat-icon>delete</mat-icon>
                        </div>
                    </td>
                </ng-container>
                <tr
                    *matHeaderRowDef="userColumns; sticky: true"
                    mat-header-row
                ></tr>
                <tr *matRowDef="let row; columns: userColumns" mat-row></tr>
            </table>
        </div>
    </div>
</div>

<ng-template #preloader>
    <div class="preloader-image"></div>
</ng-template>

<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>
