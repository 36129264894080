<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Data Type</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.dataType" [disabled]="readonly" (change)="onSave()">
                <mat-option value="vc-documents">Collection (VC)</mat-option>
                <mat-option value="did-documents">Collection (DID)</mat-option>
                <mat-option value="approve">Collection (Approve)</mat-option>
                <mat-option value="vp-documents">Collection (VP)</mat-option>
            </mat-select>
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Schema</td>
        <td class="propRowCell">
            <select-schema
                [schemas]="schemas"
                [(value)]="properties.schema"
                [disabled]="readonly"
                (change)="onSave()"
                ></select-schema>
        </td>
    </tr>

    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Owned by User</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.onlyOwnDocuments" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Owned by Group</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.onlyOwnByGroupDocuments"
                [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Assigned to User</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.onlyAssignDocuments" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Assigned to Group</td>
        <td class="propRowCell">
            <input class="prop-input" type="checkbox" [(ngModel)]="properties.onlyAssignByGroupDocuments"
                [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Order Field</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.orderField" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Order Direction</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.orderDirection" [disabled]="readonly" (change)="onSave()">
                <mat-option value=""></mat-option>
                <mat-option value="ASC">ASC</mat-option>
                <mat-option value="DESC">DESC</mat-option>
            </mat-select>
        </td>
    </tr>

    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'filtersGroup')" [attr.collapse]="propHidden.filtersGroup">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Filters</td>
        <td class="propHeaderCell">
            <div class="propAdd" (click)="addField()">
                <mat-icon>add</mat-icon>
                <span>Add Field</span>
            </div>
        </td>
    </tr>
    <ng-container *ngFor="let field of properties.filters; let i=index">
        <tr class="propRow" [attr.collapse]="propHidden.filtersGroup">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden.filters, i)" [attr.collapse]="propHidden.filters[i]">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">Field {{i}}</td>
            <td class="propRowCell">
                <span *ngIf="field.field&&field.type&&field.value"
                      [title]="field.field + ' ' + field.type + ' ' + field.value" class="not-editable-text">
                    {{field.field}} {{field.type}} {{field.value}}
                </span>
                <span class="remove-prop" [attr.readonly]="readonly" (click)="removeField(i)">
                    <mat-icon>delete</mat-icon>
                </span>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.filtersGroup || propHidden.filters[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Type</td>
            <td class="propRowCell">
                <mat-select [(value)]="field.type" [disabled]="readonly" (change)="onSave()">
                    <mat-option value="equal">Equal</mat-option>
                    <mat-option value="not_equal">Not Equal</mat-option>
                    <mat-option value="in">In</mat-option>
                    <mat-option value="not_in">Not In</mat-option>
                </mat-select>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.filtersGroup || propHidden.filters[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Field</td>
            <td class="propRowCell">
                <document-path [(value)]="field.field" [readonly]="readonly" (change)="onSave()"></document-path>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.filtersGroup || propHidden.filters[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Value</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="field.value" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
    </ng-container>
</table>
