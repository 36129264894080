<div class="container">
    <ng-container [ngTemplateOutlet]="header"></ng-container>
    <ng-container [ngTemplateOutlet]="statusContainer"></ng-container>
    <div *ngIf="false" class="loading">
        <div class="preloader-image preloader-image-l-size"></div>
    </div>
</div>

<ng-template #header>
    <div class="header">
        <h1>Services status</h1>
    </div>
</ng-template>

<ng-template #statusContainer>
    <div class="status-container">
        <ng-container *ngFor="let service of servicesStates">
            <div class="item">
                <div class="header">
                    <span>{{ service.serviceName }}</span>
                    <div class="statuses">
                        <div *ngFor="let state of service.states" [ngSwitch]="state" [pTooltip]="state">
                            <svg-icon *ngSwitchCase="'READY'"
                                      src="/assets/images/icons/check.svg"
                                svgClass="success-color">
                            </svg-icon>
                            <svg-icon *ngSwitchCase="'BAD_CONFIGURATION'"
                                      src="/assets/images/icons/warning.svg"
                                svgClass="warning-color">
                            </svg-icon>
                            <div *ngSwitchCase="'INITIALIZING'" class="preloader-image"></div>
                            <div *ngSwitchCase="'STARTED'" class="preloader-image"></div>
                            <svg-icon *ngSwitchCase="'STOPPED'"
                                      src="/assets/images/icons/time.svg"
                                svgClass="error-color">
                            </svg-icon>
                        </div>
                    </div>
                </div>
                <div class="actions-container">
                    <button *ngIf="service.serviceName !== 'LOGGER_SERVICE'"
                            [queryParams]="{ attr: service.serviceName }" [routerLink]="'/admin/logs'"
                            class="button secondary" label="View Logs"
                            pButton type="button"></button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<div *ngIf="false" class="service-status-container">
    <ng-template #info let-allServicesAreRunning="getIfAllServicesAreRunning()"
                 let-loadingServices="getLoadingServices()"
                 let-stoppedServices="getStoppedServices()">
        <h1 class="info-message" *ngIf="loadingServices.length">
            {{ getServiceNames(loadingServices) }}
            is (are) initializing
            <br/>
            Please wait...
        </h1>
        <h1 class="info-message" *ngIf="stoppedServices.length">
            There are some problems with services...
        </h1>
        <h1 *ngIf="allServicesAreRunning" class="info-message">
            All services are running
        </h1>
        <table class="status-table">
            <tr *ngFor="let service of servicesStates" class="service-status-row">
                <td class="">
                    <b>{{ service.serviceName }}</b>
                </td>
                <td class="">-</td>
                <td class="flex-line">
                    <div *ngFor="let state of service.states" [ngSwitch]="state">
                        <div *ngSwitchCase="'READY'" class="icon-row">
                            <mat-icon class="icon-status ready-status">check</mat-icon>
                            <!--                            <span>(Ready)</span>-->
                        </div>
                        <div *ngSwitchCase="'BAD_CONFIGURATION'" class="icon-row">
                            <mat-icon class="icon-status bad-configuration-status">build</mat-icon>
                            <!--                            <span>(Wrong configuration, <a [routerLink]="'/admin/logs'" [queryParams]="{ attr: service.serviceName }">View-->
                            <!--                                    Logs</a>, <a [routerLink]="'/admin/settings'">Update</a>)</span>-->
                        </div>
                        <div *ngSwitchCase="'INITIALIZING'" class="icon-row">
                            <mat-spinner [diameter]="35" *ngSwitchCase="'INITIALIZING'"></mat-spinner>
                            <!--                            <span>(Initializing)</span>-->
                        </div>
                        <div *ngSwitchCase="'STARTED'" class="icon-row">
                            <mat-spinner [diameter]="35" *ngSwitchCase="'STARTED'"></mat-spinner>
                            <!--                            <span>(Started)</span>-->
                        </div>
                        <div *ngSwitchCase="'STOPPED'" class="icon-row">
                            <mat-icon *ngSwitchCase="'STOPPED'" class="icon-status stopped-status">close</mat-icon>
                            <!--                            <span>-->
                            <!--                                (Stopped, <a [routerLink]="'/admin/logs'" [queryParams]="{ attr: service.serviceName }">View-->
                            <!--                                    Logs</a>)-->
                            <!--                            </span>-->
                        </div>
                    </div>
                    <div *ngIf="!service.states.length" class="icon-row">
                        <mat-icon class="icon-status stopped-status">close</mat-icon>
                        <span>
                            (Stopped, <a [routerLink]="'/admin/logs'" [queryParams]="{ attr: service.serviceName }">View
                            Logs</a>)
                        </span>
                    </div>
                </td>
            </tr>
        </table>
        <div class="back" *ngIf="last && !stoppedServices.length && !loadingServices.length" (click)="onBack()">
            Return to previous page
        </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="info; context: this"></ng-container>
</div>
