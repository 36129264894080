<div class="content">

    <div class="actions">
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Standard Registry</mat-label>
                <mat-select [(value)]="currentUser" (selectionChange)="onFilter('did')">
                    <mat-option value="">All</mat-option>
                    <mat-option *ngFor="let user of users" [value]="user.did">
                        <span>{{user.username}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Policy</mat-label>
                <mat-select [(value)]="currentPolicy" (selectionChange)="onFilter('policyId')">
                    <mat-option value="">All policies</mat-option>
                    <mat-option *ngFor="let policy of policies" [value]="policy.id">
                        <span>{{policy.name}}</span>
                        <span> ({{policy.id}})</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="table-container">
        <table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="table">
            <ng-container matColumnDef="policyId">
                <th mat-header-cell *matHeaderCellDef> Policy Id </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="setFilter('policyId', element.policyId)">{{element.policyId}} </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="hash">
                <th mat-header-cell *matHeaderCellDef> Hash </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="setFilter('hash', element.hash)">{{element.hash}} </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="setFilter('id', element.document.id)">{{element.document.id}} </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef> Owner </th>
                <td mat-cell *matCellDef="let element">
                    {{element.owner}}
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            </ng-container>
            <ng-container matColumnDef="createDate">
                <th mat-header-cell *matHeaderCellDef> Create Date </th>
                <td mat-cell *matCellDef="let element"> {{element.createDate}} </td>
            </ng-container>
            <ng-container matColumnDef="vp">
                <th mat-header-cell *matHeaderCellDef> Document </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="openVP(element)">View Document</a>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <div *ngIf="dataSource" class="table-paginator">
        <mat-paginator [length]="dataCount" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50, 100]" (page)="onPage($event)" aria-label="Select page">
        </mat-paginator>
    </div>

    <div *ngIf="loading" class="loading">
        <div class="preloader-image preloader-image-l-size"></div>
    </div>
</div>
