<!-- <div *ngIf="type == 'unelected'" class="content">
    <div class="btn-link" (click)="onFilters()">{{content}}</div>
</div> -->

<div *ngIf="type == 'dropdown'" class="content">
    <div class="content-name">
        {{content}}:
    </div>
    <div class="content-select">
        <mat-select [(value)]="currentValue" (selectionChange)="onFilters()">
            <mat-option *ngFor="let item of options" [value]="item.value">{{item.name}}</mat-option>
        </mat-select>
    </div>
</div>

<ng-template #preloader>
    <div class="preloader-image"></div>
</ng-template>

<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>