<form *ngIf="dataForm" [formGroup]="dataForm" [attr.immutable]="readonly">
    <div class="key-value immutable" *ngIf="!hideType">
        <div class="switch-btn">
            <label class="p-field-label" for="tokenName">Status</label>
            <switch-button [on-label]="'Published'" [off-label]="'Draft'" [(value)]="notDraftToken"></switch-button>
        </div>
    </div>
    <div class="p-field">
        <label class="p-field-label" for="tokenName">* Token Name</label>
        <input class="p-field-input" formControlName="tokenName" id="tokenName" type="text"/>
        <small *ngIf="dataForm.controls['tokenName'].invalid" class="p-error">Token name is invalid</small>
    </div>
    <div class="p-field">
        <label class="p-field-label" for="tokenSymbol">* Token Symbol</label>
        <input aria-describedby="username2-help" class="p-field-input" formControlName="tokenSymbol" id="tokenSymbol"
               type="text"/>
        <small *ngIf="dataForm.controls['tokenSymbol'].invalid" class="p-error">Token Symbol is invalid</small>
    </div>
    <div class="p-field">
        <label class="p-field-label">* Token Type</label>
        <p-dropdown formControlName="tokenType" [disabled]="readonly"
                    [options]="tokenTypes" optionLabel="name" optionValue="value"
                    (onChange)="onChangeType()">
            <ng-template let-tokenType pTemplate="item">
                <div class="dropdown-item">{{ tokenType.name }}</div>
            </ng-template>
        </p-dropdown>
    </div>
    <div *ngIf="ft" class="p-field immutable">
        <label class="p-field-label" for="decimals">Decimals</label>
        <input class="p-field-input" formControlName="decimals" id="decimals" type="text"/>
    </div>
    <div class="keys-config immutable">
        <div class="key-value">
            <div class="key-name">
                Admin Key
            </div>
            <div class="key-description">
                The key which can perform token update and token delete operations on the token. If empty, the token can
                be perceived as immutable.
            </div>
            <div class="switch-btn">
                <switch-button [(value)]="enableAdmin" [off-label]="'Disabled'" [on-label]="'Enabled'"></switch-button>
            </div>
        </div>
        <div class="key-value">
            <div class="key-name">
                Wipe Key
            </div>
            <div class="key-description">
                The key which can wipe the token balance of an account. If empty, wipe is not possible.
            </div>
            <div class="switch-btn">
                <switch-button [(value)]="enableWipe" [off-label]="'Disabled'" [on-label]="'Enabled'"></switch-button>
            </div>
        </div>
        <div class="key-value" *ngIf="enableWipe">
            <div class="key-name">
                Wipe Contract
            </div>
            <div class="key-description">
                The contract which can call wipe for token. If empty, wipe key will be used.
            </div>
            <p-dropdown class="wipe-contract-dropdown" [disabled]="readonly" formControlName="wipeContractId"
                        [options]="displayContracts" optionLabel="contractId" optionValue="contractId"
                        [showClear]="true" placeholder="Select wipe contract" emptyMessage="There are no wipe contracts">
            </p-dropdown>
        </div>
        <div class="key-value">
            <div class="key-name">
                Freeze Key
            </div>
            <div class="key-description">
                The key which can sign to freeze or unfreeze an account for token transactions. If empty, freezing is
                not possible.
            </div>
            <div class="switch-btn">
                <switch-button [(value)]="enableFreeze" [off-label]="'Disabled'" [on-label]="'Enabled'"></switch-button>
            </div>
        </div>
        <div class="key-value">
            <div class="key-name">
                KYC Key
            </div>
            <div class="key-description">
                The key which can grant or revoke KYC of an account for the token's transactions. If empty, KYC is not
                required, and KYC grant or revoke operations are not possible.
            </div>
            <div class="switch-btn">
                <switch-button [(value)]="enableKYC" [off-label]="'Disabled'" [on-label]="'Enabled'"></switch-button>
            </div>
        </div>
    </div>
</form>

<form *ngIf="false" [attr.immutable]="readonly" [formGroup]="dataForm">
    <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>* Token Name</mat-label>
        <input matInput type="text" placeholder="Token Name" formControlName="tokenName">
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>* Token Symbol</mat-label>
        <input matInput type="text" placeholder="F" formControlName="tokenSymbol">
    </mat-form-field>

    <mat-form-field appearance="outline" class="immutable">
        <mat-label>* Token Type</mat-label>
        <mat-select formControlName="tokenType" (selectionChange)="onChangeType()">
            <mat-option value="fungible">Fungible</mat-option>
            <mat-option value="non-fungible">Non-Fungible</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="ft" class="example-full-width immutable" appearance="outline">
        <mat-label>Decimals</mat-label>
        <input matInput type="text" placeholder="2" formControlName="decimals">
    </mat-form-field>

    <div class="delimiter"></div>

    <div class="keys-config immutable">
        <div class="key-value">
            <div class="switch-btn">
                <switch-button [on-label]="'Enabled'" [off-label]="'Disabled'" [(value)]="enableAdmin"></switch-button>
            </div>
            <div class="key-name">
                Admin Key
            </div>
            <div class="key-description">
                The key which can perform token update and token delete operations on the token. If empty, the token can
                be perceived as immutable.
            </div>
        </div>
        <div class="key-value">
            <div class="switch-btn">
                <switch-button [on-label]="'Enabled'" [off-label]="'Disabled'" [(value)]="enableWipe"></switch-button>
            </div>
            <div class="key-name">
                Wipe Key
            </div>
            <div class="key-description">
                The key which can wipe the token balance of an account. If empty, wipe is not possible.
            </div>
        </div>
        <div class="key-value">
            <div class="switch-btn">
                <switch-button [on-label]="'Enabled'" [off-label]="'Disabled'" [(value)]="enableFreeze"></switch-button>
            </div>
            <div class="key-name">
                Freeze Key
            </div>
            <div class="key-description">
                The key which can sign to freeze or unfreeze an account for token transactions. If empty, freezing is
                not possible.
            </div>
        </div>
        <div class="key-value">
            <div class="switch-btn">
                <switch-button [on-label]="'Enabled'" [off-label]="'Disabled'" [(value)]="enableKYC"></switch-button>
            </div>
            <div class="key-name">
                KYC Key
            </div>
            <div class="key-description">
                The key which can grant or revoke KYC of an account for the token's transactions. If empty, KYC is not
                required, and KYC grant or revoke operations are not possible.
            </div>
        </div>
    </div>
</form>
