<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Entity Type</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.entityType" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.filtersGroup">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Schema</td>
        <td class="propRowCell">
            <select-schema
                [schemas]="schemas" 
                [(value)]="properties.schema"
                [disabled]="readonly" 
                (change)="onSave()"
                ></select-schema>
        </td>
    </tr>
</table>
