<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'main')" [attr.collapse]="propHidden.main">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">UI</td>
        <td class="propHeaderCell"></td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Title</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.uiMetaData.title" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Enable common sorting</td>
        <td class="propRowCell">
            <input type="checkbox" [(ngModel)]="properties.uiMetaData.enableSorting" [readonly]="readonly"
                (blur)="onSave()">
        </td>
    </tr>
    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'fieldsGroup')"
            [attr.collapse]="propHidden.fieldsGroup">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Fields</td>
        <td class="propHeaderCell">
            <div class="propAdd" (click)="addField()">
                <mat-icon>add</mat-icon>
                <span>Add Field</span>
            </div>
        </td>
    </tr>
    <ng-container *ngFor="let field of properties.uiMetaData.fields; let i=index">
        <tr class="propRow" [attr.collapse]="propHidden.fieldsGroup">
            <td class="propRowCol cellCollapse" (click)="onHide(propHidden.fields, i)"
                [attr.collapse]="propHidden.fields[i]">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">{{getFieldName(field, i)}}</td>
            <td class="propRowCell not-editable-text">
                <span>{{field.name}}</span>
                <span class="remove-prop" [attr.readonly]="readonly" (click)="removeField(i)">
                    <mat-icon>delete</mat-icon>
                </span>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Field Path</td>
            <td class="propRowCell">
                <document-path [(value)]="field.name" [readonly]="readonly" (change)="onSave()"></document-path>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Type</td>
            <td class="propRowCell">
                <mat-select [(value)]="field.type" (selectionChange)="onSelectFieldType(field)" [disabled]="readonly">
                    <mat-option value="text">Text</mat-option>
                    <mat-option value="issuer">Issuer</mat-option>
                    <mat-option value="button">Button</mat-option>
                    <mat-option value="block">Block</mat-option>
                    <mat-option value="array">Array</mat-option>
                    <mat-option value="serials">Serials</mat-option>
                </mat-select>
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Title</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="field.title" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Tooltip</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="field.tooltip" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">Cell Content</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="field.content" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">UI Class</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="field.uiClass" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">width</td>
            <td class="propRowCell">
                <input class="prop-input" [(ngModel)]="field.width" [readonly]="readonly" (blur)="onSave()">
            </td>
        </tr>
        <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
            <td class="propRowCol"></td>
            <td class="propRowCell cellName">
                <mat-icon class="icon-link">add_link</mat-icon> Bind Group
            </td>
            <td class="propRowCell">
                <select-block [root]="module" [(value)]="field.bindGroup" [readonly]="readonly"
                    [blocks]="allBlocks"></select-block>
            </td>
        </tr>
        <ng-container *ngIf="field.type=='button'">
            <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">
                    <mat-icon class="icon-event">
                        flash_on
                    </mat-icon>
                    Action
                </td>
                <td class="propRowCell">
                    <mat-select [(value)]="field.action" [disabled]="readonly" (change)="onSave()">
                        <mat-option value="link">LINK</mat-option>
                        <mat-option value="dialog">DIALOG</mat-option>
                        <mat-option value="download">DOWNLOAD</mat-option>
                    </mat-select>
                </td>
            </tr>
            <ng-container *ngIf="field.action=='link'">
                <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
                    <td class="propRowCol"></td>
                    <td class="propRowCell cellName">
                        <mat-icon class="icon-link">
                            add_link
                        </mat-icon>
                        Bind Block
                    </td>
                    <td class="propRowCell">
                        <select-block [root]="module" [(value)]="field.bindBlock" [readonly]="readonly"
                            [blocks]="allBlocks"></select-block>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="field.action=='dialog'">
                <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
                    <td class="propRowCol"></td>
                    <td class="propRowCell cellName">Dialog Type</td>
                    <td class="propRowCell">
                        <input class="prop-input" [(ngModel)]="field.dialogType" [readonly]="readonly"
                            (blur)="onSave()">
                    </td>
                </tr>
                <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
                    <td class="propRowCol"></td>
                    <td class="propRowCell cellName">Dialog Content</td>
                    <td class="propRowCell">
                        <input class="prop-input" [(ngModel)]="field.dialogContent" [readonly]="readonly"
                            (blur)="onSave()">
                    </td>
                </tr>
                <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
                    <td class="propRowCol"></td>
                    <td class="propRowCell cellName">Dialog Class</td>
                    <td class="propRowCell">
                        <input class="prop-input" [(ngModel)]="field.dialogClass" [readonly]="readonly"
                            (blur)="onSave()">
                    </td>
                </tr>
                <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
                    <td class="propRowCol"></td>
                    <td class="propRowCell cellName">
                        <mat-icon class="icon-link">
                            add_link
                        </mat-icon>
                        Bind Block
                    </td>
                    <td class="propRowCell">
                        <select-block [root]="module" [(value)]="field.bindBlock" [readonly]="readonly"
                            [blocks]="allBlocks"></select-block>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="field.type=='block'">
            <tr class="propRow subRow" [attr.collapse]="propHidden.fieldsGroup || propHidden.fields[i]">
                <td class="propRowCol"></td>
                <td class="propRowCell cellName">
                    <mat-icon class="icon-link">
                        add_link
                    </mat-icon>
                    Bind Block
                </td>
                <td class="propRowCell">
                    <select-block [root]="module" [(value)]="field.bindBlock" [readonly]="readonly"
                        [blocks]="allBlocks"></select-block>
                </td>
            </tr>
        </ng-container>
    </ng-container>
</table>
