<!-- UI Prop -->
<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr class="propHeader">
        <td class="propRowCol cellCollapse" (click)="onHide(propHidden, 'options')" [attr.collapse]="propHidden.options">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName">Options</td>
        <td class="propHeaderCell"></td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Start Date</td>
        <td class="propRowCell">
            <datetime-picker [(value)]="properties.startDate" [readonly]="readonly"></datetime-picker>
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">End Date</td>
        <td class="propRowCell">
            <datetime-picker [(value)]="properties.endDate" [readonly]="readonly"></datetime-picker>
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Period</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.period" [disabled]="readonly" (closed)="selectPeriod()">
                <mat-option value="yearly">Yearly</mat-option>
                <mat-option value="monthly">Monthly</mat-option>
                <mat-option value="weekly">Weekly</mat-option>
                <mat-option value="daily">Daily</mat-option>
                <mat-option value="hourly">Hourly</mat-option>
                <mat-option value="custom">Custom</mat-option>
            </mat-select>
        </td>
    </tr>
    <tr class="propRow" *ngIf="properties.period == 'custom'" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Mask</td>
        <td class="propRowCell">
            <span>{{properties.periodMask}}</span>
        </td>
    </tr>
    <tr class="propRow" *ngIf="properties.period == 'custom'" [attr.collapse]="propHidden.options">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Interval</td>
        <td class="propRowCell">
            <span>{{properties.periodInterval}}</span>
        </td>
    </tr>

</table>