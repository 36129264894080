<div class="dialog-body">
    <div *ngIf="loading" class="loading">
        <div class="preloader-image preloader-image-l-size"></div>
    </div>

    <div *ngIf="schema" class="policy">
        <div *ngIf="schema.description">
            <div class="field-name">Description</div>
            <div class="field-value">{{ schema.description }}</div>
        </div>

        <div *ngIf="schema.version">
            <div class="field-name">Version</div>
            <div class="field-value">{{ schema.version }}</div>
        </div>

        <div class="field">
            <div class="field-name">Creator</div>
            <div class="field-value">{{ schema.owner }}</div>
        </div>

    </div>
</div>

<div class="dialog-footer">
    <div class="action-buttons">
        <div class="copy-button">
            <button (click)="handleCopyToClipboard(schema.messageId)" [disabled]="!schema.messageId"
                    class="button secondary"
                    label="Copy message identifier"
                    pButton
            ></button>
        </div>
        <div>
            <button (click)="saveToFile()" class="button" label="Save to file" pButton style="margin-right: 15px"></button>
        </div>
        <div>
            <button (click)="saveToExcel()" class="button" label="Save to Excel" pButton></button>
        </div>
    </div>
</div>
