<div class="content">
    <div *ngIf="loading" class="loading">
        <mat-spinner></mat-spinner>
    </div>

    <ng-container *ngIf="!owner">
        <div class="not-exist">
            Before starting work you need to get DID <a [routerLink]="['/profile']">here</a>
        </div>
    </ng-container>

    <ng-container *ngIf="!policyId">
        <div class="not-exist">No results found.</div>
    </ng-container>


    <div *ngIf="owner && policyId && results" class="content-result">
        <app-compare-record [value]="results"></app-compare-record>
    </div>
</div>