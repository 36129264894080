<ngx-file-drop 
    [multiple]="multiple" 
    dropZoneClassName="file-drop-zone" 
    contentClassName="file-drop-zone-content"
    (click)="importFromFile()" 
	(onFileDrop)="droppedFile($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="attach-file-label">
            <mat-icon> attach_file</mat-icon>
            {{ dropZoneLabel }}
        </div>
    </ng-template>
</ngx-file-drop>
