
export enum BlockGroup {
    Main = 'Main',
    Documents = 'Documents',
    Tokens = 'Tokens',
    Calculate = 'Calculate',
    Report = 'Report',
    UnGrouped = 'UnGrouped',
    Module = 'Module',
    Tool = 'tool',
}
