<table class="properties" [attr.readonly]="readonly" *ngIf="properties">
    <tr *ngIf="!properties.useTemplate" class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Token</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.tokenId" [disabled]="readonly" (change)="onSave()">
                <mat-option *ngFor="let token of tokens" [value]="token.value">
                    {{token.name}}
                </mat-option>
            </mat-select>
        </td>
    </tr>
    <tr *ngIf="properties.useTemplate" class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Token Template</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.template" [disabled]="readonly" (change)="onSave()">
                <mat-option *ngFor="let token of tokenTemplate" [value]="token.value">
                    {{token.name}}
                </mat-option>
            </mat-select>
        </td>
    </tr>
    <tr class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Account Type</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.accountType" [disabled]="readonly" (change)="onSave()">
                <mat-option value="default">Default</mat-option>
                <mat-option value="custom">Custom</mat-option>
            </mat-select>
        </td>
    </tr>
    <tr *ngIf="properties.accountType==='custom'" class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Account Id (Field)</td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="properties.accountId" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
    <tr *ngIf="properties.accountType!=='custom'" class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Action</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.action" [disabled]="readonly" (change)="onSave()">
                <mat-option value="associate">Associate</mat-option>
                <mat-option value="dissociate">Dissociate</mat-option>
                <mat-option value="freeze">Freeze</mat-option>
                <mat-option value="unfreeze">Unfreeze</mat-option>
                <mat-option value="grantKyc">Grant Kyc</mat-option>
                <mat-option value="revokeKyc">Revoke Kyc</mat-option>
            </mat-select>
        </td>
    </tr>
    <tr *ngIf="properties.accountType==='custom'" class="propRow" [attr.collapse]="propHidden.main">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName">Action</td>
        <td class="propRowCell">
            <mat-select [(value)]="properties.action" [disabled]="readonly" (change)="onSave()">
                <mat-option value="freeze">Freeze</mat-option>
                <mat-option value="unfreeze">Unfreeze</mat-option>
                <mat-option value="grantKyc">Grant Kyc</mat-option>
                <mat-option value="revokeKyc">Revoke Kyc</mat-option>
            </mat-select>
        </td>
    </tr>
</table>