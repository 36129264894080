<ng-container *ngIf="property.type === 'Group' && visible(property.visible)">
    <tr class="propHeader" [attr.collapse]="collapse">
        <td class="propRowCol cellCollapse" (click)="onHide()" [attr.collapse]="groupCollapse">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName" [title]="property.title">
            <span *ngIf="property.required" class="required-fields">*</span> {{property.label}}
        </td>
        <td class="propHeaderCell"></td>
    </tr>

    <div *ngFor="let p of property.properties" common-property class="custom-properties"
        [data]="group"
        [property]="p"
        [readonly]="readonly"
        [collapse]="collapse || groupCollapse"
        [block]="currentBlock"
        (update)="onSave()"></div>
</ng-container>

<ng-container *ngIf="property.type === 'Array' && visible(property.visible)">
    <tr class="propHeader" [attr.collapse]="collapse">
        <td class="propRowCol cellCollapse" (click)="onHide()" [attr.collapse]="groupCollapse">
            <mat-icon>arrow_drop_down</mat-icon>
        </td>
        <td class="propHeaderCell cellName" [title]="property.title">
            <span *ngIf="property.required" class="required-fields">*</span> {{property.label}}
        </td>
        <td class="propHeaderCell">
            <div class="propAdd" (click)="addItems()">
                <mat-icon>add</mat-icon>
                <span>Add {{property.items.label}}</span>
            </div>
        </td>
    </tr>
    <ng-container *ngFor="let item of value; let i=index">
        <tr class="propRow" [attr.collapse]="collapse || groupCollapse">
            <td class="propRowCol cellCollapse" (click)="onHideItem(i)" [attr.collapse]="groupCollapse || itemCollapse[i]">
                <mat-icon>arrow_drop_down</mat-icon>
            </td>
            <td class="propRowCell cellName">
                {{property.items.label}} {{i}}
            </td>
            <td class="propRowCell">
                <span class="not-editable-text">
                    {{getArrayItemText(property.items, item)}}
                </span>
                <span class="remove-prop" [attr.readonly]="readonly" (click)="removeItems(i)">
                    <mat-icon>delete</mat-icon>
                </span>
            </td>
        </tr>
        <div *ngFor="let p of property.items.properties" common-property class="custom-properties"
            [data]="item"
            [property]="p"
            [readonly]="readonly"
            [collapse]="collapse || groupCollapse || itemCollapse[i]"
            [offset]="1"
            [block]="currentBlock"
            (update)="onSave()"></div>
    </ng-container>
</ng-container>

<ng-container *ngIf="property.type === 'Input' && visible(property.visible)">
    <tr class="propRow" [attr.offset]="offset" [attr.collapse]="collapse">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName" [title]="property.title">
            <span *ngIf="property.required" class="required-fields">*</span> {{property.label}}
        </td>
        <td class="propRowCell">
            <input class="prop-input" [(ngModel)]="value" [readonly]="readonly" (blur)="onSave()">
        </td>
    </tr>
</ng-container>

<ng-container *ngIf="property.type === 'Checkbox' && visible(property.visible)">
    <tr class="propRow" [attr.offset]="offset" [attr.collapse]="collapse">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName" [title]="property.title">
            <span *ngIf="property.required" class="required-fields">*</span> {{property.label}}
        </td>
        <td class="propRowCell">
            <input class="prop-input prop-checkbox" type="checkbox" [(ngModel)]="value" [readonly]="readonly"
                   (change)="onSave()">
        </td>
    </tr>
</ng-container>

<ng-container *ngIf="property.type === 'Select' && visible(property.visible)">
    <tr class="propRow" [attr.offset]="offset" [attr.collapse]="collapse">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName" [title]="property.title">
            <span *ngIf="property.required" class="required-fields">*</span> {{property.label}}
        </td>
        <td class="propRowCell ns" [ngSwitch]="property.items">
            <mat-select *ngSwitchCase="'Roles'" [(value)]="value" [disabled]="readonly" (change)="onSave()">
                <mat-option *ngFor="let item of roles" [value]="item.value">{{item.name}}</mat-option>
            </mat-select>
            <mat-select *ngSwitchDefault [(value)]="value" [disabled]="readonly" (change)="onSave()">
                <mat-option *ngFor="let item of property.items" [value]="item.value">{{item.label}}</mat-option>
            </mat-select>
        </td>
    </tr>
</ng-container>

<ng-container *ngIf="property.type === 'MultipleSelect' && visible(property.visible)">
    <tr class="propRow" [attr.offset]="offset" [attr.collapse]="collapse">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName" [title]="property.title">
            <span *ngIf="property.required" class="required-fields">*</span> {{property.label}}
        </td>
        <td *ngIf="loaded" [ngSwitch]="property.items" class="propRowCell ns">
            <mat-select 
                *ngSwitchCase="'Schemas'" 
                multiple 
                [(value)]="value" 
                [disabled]="readonly" 
                (change)="onSave()">
                <mat-option 
                    *ngFor="let schema of schemas" 
                    class="schema-variable"
                    [attr.status]="schema.status"
                    matTooltipPosition="below" 
                    matTooltipClass="guardian-tooltip small-guardian-tooltip" 
                    matTooltipShowDelay="500"
                    [matTooltip]="schema.tooltip"
                    [value]="schema.value"
                    [attr.disable]="schema.disable"
                >{{schema.displayName}}</mat-option>
            </mat-select>
            <mat-select *ngSwitchCase="'Block'" multiple [(value)]="value" [disabled]="readonly"
                (valueChange)="onSave()">
                <mat-select-trigger>
                    <span class="select-custom">{{value}}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let item of allBlocks" [value]="item.value">
                    <div class="select-block-name">
                        <div class="select-block-icon">
                            <mat-icon>{{item.icon}}</mat-icon>
                        </div>
                        {{item.name}}
                    </div>
                </mat-option>
            </mat-select>
            <mat-select *ngSwitchCase="'Children'" multiple [(value)]="value" [disabled]="readonly"
                (valueChange)="onSave()">
                <mat-select-trigger>
                    <span class="select-custom">{{value}}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let item of childrenBlocks" [value]="item.value">
                    <div class="select-block-name">
                        <div class="select-block-icon">
                            <mat-icon>{{item.icon}}</mat-icon>
                        </div>
                        {{item.name}}
                    </div>
                </mat-option>
            </mat-select>
            <mat-select *ngSwitchCase="'Roles'" multiple [(value)]="value" [disabled]="readonly" (change)="onSave()">
                <mat-option *ngFor="let item of roles" [value]="item.value">{{item.name}}</mat-option>
            </mat-select>
            <mat-select *ngSwitchDefault multiple [(value)]="value" [disabled]="readonly" (change)="onSave()">
                <mat-option *ngFor="let item of property.items" [value]="item.value">{{item.label}}</mat-option>
            </mat-select>
        </td>
    </tr>
</ng-container>

<ng-container *ngIf="property.type === 'Schemas' && visible(property.visible)">
    <tr class="propRow" [attr.offset]="offset" [attr.collapse]="collapse">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName" [title]="property.title">
            <span *ngIf="property.required" class="required-fields">*</span> {{property.label}}
        </td>
        <td class="propRowCell ns">
            <select-schema
                [schemas]="schemas" 
                [(value)]="value"
                [disabled]="readonly"
                (change)="onSave()"
                ></select-schema>
        </td>
    </tr>
</ng-container>

<ng-container *ngIf="property.type === 'Path' && visible(property.visible)">
    <tr class="propRow" [attr.offset]="offset" [attr.collapse]="collapse">
        <td class="propRowCol"></td>
        <td class="propRowCell cellName" [title]="property.title">
            <span *ngIf="property.required" class="required-fields">*</span> {{property.label}}
        </td>
        <td class="propRowCell">
            <document-path [(value)]="value" [readonly]="readonly" (change)="onSave()"></document-path>
        </td>
    </tr>
</ng-container>
