<div class="content">
    <div class="page-title">Schemas</div>

    <div class="actions">

        <div class="tabs-nav">
            <p-tabView>
                <p-tabPanel *ngFor="let tab of schemasTypes" [selected]="tab.value === type">
                    <ng-template pTemplate="header">
                        <div (click)="onChangeType(tab.value)" class="tabview-label">{{ tab.label }}</div>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </div>

        <div class="actions-controls-container">
            <div class="policy-dropdown-container">
                <ng-container *ngIf="isPolicy">
                    <p-dropdown
                        (onChange)="onFilter($event)"
                        [(ngModel)]="currentTopic"
                        [options]="policies"
                        [showClear]="false"
                        class="policy-dropdown"
                        optionLabel="name"
                        optionValue="topicId"
                        placeholder="Policy"></p-dropdown>
                </ng-container>
                <ng-container *ngIf="isTool">
                    <p-dropdown
                        (onChange)="onFilter($event)"
                        [(ngModel)]="currentTopic"
                        [options]="tools"
                        [showClear]="true"
                        class="policy-dropdown"
                        optionLabel="name"
                        optionValue="topicId"
                        placeholder="Tool"></p-dropdown>
                </ng-container>
            </div>
            <div class="schema-actions-buttons-container">
                <p-button
                    *ngIf="canCreate"
                    (click)="onCreateSchemas()"
                    class="toolbar-btn-primary"
                    [pTooltip]="'Create a Schema'"
                    tooltipPosition="bottom">
                    <svg-icon src="/assets/images/icons/add.svg" svgClass="color-white"></svg-icon>
                </p-button>
                <p-button
                    *ngIf="canImport"
                    (click)="downloadExcelExample()"
                    class="toolbar-btn-primary"
                    [pTooltip]="'Generate Excel Template'"
                    tooltipPosition="bottom">
                    <svg-icon src="/assets/images/icons/wizard.svg" svgClass="color-white"></svg-icon>
                </p-button>
                <p-button
                    *ngIf="canImport"
                    (click)="onImportSchemas()"
                    class="toolbar-btn-primary"
                    [pTooltip]="'Import'"
                    tooltipPosition="bottom">
                    <svg-icon src="/assets/images/icons/import.svg" svgClass="color-white"></svg-icon>
                </p-button>
                <p-button
                    *ngIf="isPolicy || isModule || isTool"
                    (click)="onCompare()"
                    [pTooltip]="'Compare'"
                    class="toolbar-btn-secondary"
                    styleClass="p-button-outlined"
                    tooltipPosition="bottom">
                    <svg-icon src="/assets/images/icons/compare.svg" svgClass="primary-color"></svg-icon>
                </p-button>
            </div>
        </div>
    </div>

    <div *ngIf="isAny" class="table-container">
        <p-table [value]="page">
            <ng-template pTemplate="header">
                <tr>
                    <ng-container *ngIf="type === 'policy'">
                        <th class="header-cell-text" style="min-width: 140px; max-width: 150px;">
                            Policy
                        </th>
                        <th class="header-cell-text" style="min-width: 140px; max-width: 150px;">
                            Name
                        </th>
                        <th class="header-cell-text" style="min-width: 140px;">
                            Topic
                        </th>
                        <th class="header-cell-text" style="min-width: 100px;">
                            Version
                        </th>
                        <th class="header-cell-text" style="min-width: 100px;">
                            Entity
                        </th>
                        <th class="header-cell-text" style="min-width: 185px;">
                            Tags
                        </th>
                        <th class="header-cell-text" style="min-width: 100px;">
                            Status
                        </th>
                        <th class="header-cell-text" style="min-width: 140px;">
                            Operations
                        </th>
                    </ng-container>
                    <ng-container *ngIf="type === 'module'">
                        <th class="header-cell-text" style="min-width: 140px; max-width: 150px;">
                            Name
                        </th>
                        <th class="header-cell-text" style="min-width: 100px;">
                            Status
                        </th>
                        <th class="header-cell-text" style="min-width: 140px;">
                            Operations
                        </th>
                    </ng-container>
                    <ng-container *ngIf="type === 'tool'">
                        <th class="header-cell-text" style="min-width: 140px; max-width: 150px;">
                            Tool
                        </th>
                        <th class="header-cell-text" style="min-width: 140px; max-width: 150px;">
                            Name
                        </th>
                        <th class="header-cell-text" style="min-width: 100px;">
                            Status
                        </th>
                        <th class="header-cell-text" style="min-width: 140px;">
                            Operations
                        </th>
                    </ng-container>
                    <ng-container *ngIf="type === 'system'">
                        <th class="header-cell-text" style="min-width: 140px; max-width: 150px;">
                            Name
                        </th>
                        <th class="header-cell-text" style="min-width: 140px;">
                            Owner
                        </th>
                        <th class="header-cell-text" style="min-width: 100px;">
                            Entity
                        </th>
                        <th class="header-cell-text" style="min-width: 100px;">
                            Status
                        </th>
                        <th class="header-cell-text" style="min-width: 140px;">
                            Operations
                        </th>
                    </ng-container>
                    <ng-container *ngIf="type === 'tag'">
                        <th class="header-cell-text" style="min-width: 140px; max-width: 150px;">
                            Name
                        </th>
                        <th class="header-cell-text" style="min-width: 140px;">
                            Owner
                        </th>
                        <th class="header-cell-text" style="min-width: 100px;">
                            Status
                        </th>
                        <th class="header-cell-text" style="min-width: 140px;">
                            Operations
                        </th>
                    </ng-container>
                    <th class="header-cell-text" style="min-width: 110px; width: 110px; max-width: 110px;">
                    </th>
                </tr>
            </ng-template>
            <ng-template let-schema pTemplate="body">
                <tr class="schemas-grid-row" [attr.schema-status]="schema.status">

                    <ng-container *ngIf="type === 'policy'">
                        <td class="first-schema-grid-cell text-truncate" style="min-width: 140px; max-width: 150px;">
                            <a 
                                [queryParams]="{ policyId: policyIdByTopic[schema.topicId] }" 
                                [routerLink]="['/policy-configuration']"
                            >
                                {{policyNameByTopic[schema.topicId]}}
                            </a>
                        </td>
                        <td [pTooltip]="schema.name" class="text-truncate" style="min-width: 140px; max-width: 150px;">
                            {{ schema.name }}
                        </td>
                        <td style="min-width: 140px;">
                            <ng-container *ngIf="schema.topicId === 'draft'; else topicExplorer">
                                <span>Draft</span>
                            </ng-container>
                            <ng-template #topicExplorer>
                                <hedera-explorer [params]="schema.topicId" type="topics">{{ schema.topicId }}</hedera-explorer>
                            </ng-template>
                        </td>
                        <td style="min-width: 100px;">
                            {{ schema.version || schema.sourceVersion }}
                        </td>
                        <td style="min-width: 100px;">
                            {{ schema.entity }}
                        </td>
                        <td style="min-width: 185px;">
                            <tags-explorer
                                    *ngIf="user.TAGS_TAG_READ"
                                    [user]="user"
                                    [data]="schema._tags"
                                    [entity]="tagEntity"
                                    [owner]="owner"
                                    [schemas]="tagSchemas"
                                    [service]="tagsService"
                                    [target]="schema.id"></tags-explorer>
                        </td>
                        <td style="min-width: 100px;">
                            <span [ngSwitch]="schema.status">
                                <div *ngSwitchCase="'DRAFT'">
                                    <span class="schema-status status-DRAFT">Draft</span>
                                </div>
                                <div *ngSwitchCase="'PUBLISHED'">
                                    <span class="schema-status status-PUBLISHED">Published</span>
                                </div>
                                <div *ngSwitchCase="'UNPUBLISHED'">
                                    <span class="schema-status status-UNPUBLISHED">Unpublished</span>
                                </div>
                                <div *ngSwitchCase="'ERROR'">
                                    <span class="schema-status status-ERROR">Incomplete</span>
                                </div>
                                <div *ngSwitchDefault>
                                    <span class="schema-status">{{schema.status}}</span>
                                </div>
                            </span>
                        </td>
                        <td class="last-schema-grid-cell" style="min-width: 140px;">
                            <div class="operations-row-container">
                                <div>
                                    <div
                                        (click)="onPublish(schema)"
                                        *ngIf="schema.status == 'DRAFT'"
                                        [attr.disabled]="schema.topicId === 'draft'"
                                        class="btn-approve">Publish</div>
                                    <div
                                        (click)="onPublish(schema)"
                                        *ngIf="schema.status == 'UNPUBLISHED'"
                                        [attr.disabled]="schema.topicId === 'draft'"
                                        class="btn-approve">Publish</div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="type === 'module'">
                        <td [pTooltip]="schema.name" class="text-truncate" style="min-width: 140px; max-width: 150px;">
                            {{ schema.name }}
                        </td>
                        <td style="min-width: 100px;">
                            <span [ngSwitch]="schema.status">
                                <div *ngSwitchCase="'DRAFT'">
                                    <span class="schema-status status-DRAFT">Draft</span>
                                </div>
                                <div *ngSwitchCase="'PUBLISHED'">
                                    <span class="schema-status status-PUBLISHED">Published</span>
                                </div>
                                <div *ngSwitchCase="'UNPUBLISHED'">
                                    <span class="schema-status status-UNPUBLISHED">Unpublished</span>
                                </div>
                                <div *ngSwitchCase="'ERROR'">
                                    <span class="schema-status status-ERROR">Incomplete</span>
                                </div>
                                <div *ngSwitchDefault>
                                    <span class="schema-status">{{schema.status}}</span>
                                </div>
                            </span>
                        </td>
                        <td class="last-schema-grid-cell" style="min-width: 140px;">
                            <div class="operations-row-container">
                                <div>
                                    <div
                                        (click)="onPublish(schema)"
                                        *ngIf="schema.status == 'DRAFT'"
                                        [attr.disabled]="schema.topicId === 'draft'"
                                        class="btn-approve">Publish</div>
                                    <div
                                        (click)="onPublish(schema)"
                                        *ngIf="schema.status == 'UNPUBLISHED'"
                                        [attr.disabled]="schema.topicId === 'draft'"
                                        class="btn-approve">Publish</div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="type === 'tool'">
                        <td class="first-schema-grid-cell text-truncate" style="min-width: 140px; max-width: 150px;">
                            <a [queryParams]="{toolId: toolIdByTopic[schema.topicId]}"
                                [routerLink]="'/tool-configuration'">
                                {{ toolNameByTopic[schema.topicId] }}
                            </a>
                        </td>
                        <td [pTooltip]="schema.name" class="text-truncate" style="min-width: 140px; max-width: 150px;">
                            {{ schema.name }}
                        </td>
                        <td style="min-width: 100px;">
                            <span [ngSwitch]="schema.status">
                                <div *ngSwitchCase="'DRAFT'">
                                    <span class="schema-status status-DRAFT">Draft</span>
                                </div>
                                <div *ngSwitchCase="'PUBLISHED'">
                                    <span class="schema-status status-PUBLISHED">Published</span>
                                </div>
                                <div *ngSwitchCase="'UNPUBLISHED'">
                                    <span class="schema-status status-UNPUBLISHED">Unpublished</span>
                                </div>
                                <div *ngSwitchCase="'ERROR'">
                                    <span class="schema-status status-ERROR">Incomplete</span>
                                </div>
                                <div *ngSwitchDefault>
                                    <span class="schema-status">{{schema.status}}</span>
                                </div>
                            </span>
                        </td>
                        <td class="last-schema-grid-cell" style="min-width: 140px;">
                            <div class="operations-row-container">
                                <div>
                                    <div
                                        (click)="onPublish(schema)"
                                        *ngIf="schema.status == 'DRAFT'"
                                        [attr.disabled]="schema.topicId === 'draft'"
                                        class="btn-approve">Publish</div>
                                    <div
                                        (click)="onPublish(schema)"
                                        *ngIf="schema.status == 'UNPUBLISHED'"
                                        [attr.disabled]="schema.topicId === 'draft'"
                                        class="btn-approve">Publish</div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="type === 'system'">
                        <td [pTooltip]="schema.name" class="text-truncate" style="min-width: 140px; max-width: 150px;">
                            {{ schema.name }}
                        </td>
                        <td style="min-width: 140px;">
                            {{ schema.owner }}
                        </td>
                        <td style="min-width: 100px;">
                            {{ schema.entity }}
                        </td>
                        <td style="min-width: 100px;">
                            <span>
                                <div *ngIf="!schema.active">
                                    <span class="schema-status status-DRAFT">Inactive</span>
                                </div>
                                <div *ngIf="schema.active">
                                    <span class="schema-status status-PUBLISHED">Active</span>
                                </div>
                            </span>
                        </td>
                        <td class="last-schema-grid-cell" style="min-width: 140px;">
                            <div class="operations-row-container">
                                <div>
                                    <div (click)="onActive(schema)" *ngIf="!schema.active" class="btn-approve">
                                        Active
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="type === 'tag'">
                        <td [pTooltip]="schema.name" class="text-truncate" style="min-width: 140px; max-width: 150px;">
                            {{ schema.name }}
                        </td>
                        <td style="min-width: 140px;">
                            {{ schema.owner }}
                        </td>
                        <td style="min-width: 100px;">
                            <span [ngSwitch]="schema.status">
                                <div *ngSwitchCase="'DRAFT'">
                                    <span class="schema-status status-DRAFT">Draft</span>
                                </div>
                                <div *ngSwitchCase="'PUBLISHED'">
                                    <span class="schema-status status-PUBLISHED">Published</span>
                                </div>
                                <div *ngSwitchCase="'UNPUBLISHED'">
                                    <span class="schema-status status-UNPUBLISHED">Unpublished</span>
                                </div>
                                <div *ngSwitchCase="'ERROR'">
                                    <span class="schema-status status-ERROR">Incomplete</span>
                                </div>
                                <div *ngSwitchDefault>
                                    <span class="schema-status">{{schema.status}}</span>
                                </div>
                            </span>
                        </td>
                        <td class="last-schema-grid-cell" style="min-width: 140px;">
                            <div class="operations-row-container">
                                <div>
                                    <div
                                        (click)="onPublishTagSchema(schema)"
                                        *ngIf="schema.status == 'DRAFT'"
                                        [attr.disabled]="schema.topicId === 'draft'"
                                        class="btn-approve">Publish</div>
                                    <div
                                        (click)="onPublishTagSchema(schema)"
                                        *ngIf="schema.status == 'UNPUBLISHED'"
                                        [attr.disabled]="schema.topicId === 'draft'"
                                        class="btn-approve">Publish</div>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <td class="last-schema-grid-cell" style="min-width: 110px; width: 110px; max-width: 110px;">
                        <div class="operations-row-container">
                            <div style="margin-right: 12px;">
                                <div
                                    *ngIf="ifCanEdit(schema)"
                                    (click)="onEditSchema(schema)"
                                    class="btn-settings"
                                    matTooltip="Edit"
                                    matTooltipClass="guardian-tooltip"
                                    matTooltipPosition="above">
                                    <svg-icon src="/assets/images/icons/edit.svg" svgClass="color-primary"></svg-icon>
                                </div>
                                <div
                                    *ngIf="!ifCanEdit(schema)"
                                    class="btn-settings"
                                    matTooltip="Edit"
                                    matTooltipClass="guardian-tooltip"
                                    matTooltipPosition="above">
                                    <svg-icon src="/assets/images/icons/edit.svg" svgClass="color-grey"></svg-icon>
                                </div>
                            </div>
                            <div>
                                <div
                                    class="btn-settings"
                                    [matMenuTriggerFor]="schemaMenu"
                                    [matMenuTriggerData]="{ schema }"
                                    matTooltip="Menu"
                                    matTooltipClass="guardian-tooltip"
                                    matTooltipPosition="above">
                                    <svg-icon src="/assets/images/icons/menu.svg" svgClass="color-primary"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template let-columns pTemplate="emptymessage"></ng-template>
        </p-table>
    </div>

    <app-paginator
        *ngIf="isAny"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [length]="count"
        (page)="onPage($event)"
    ></app-paginator>

    <div *ngIf="!isSystem && !isConfirmed" class="not-exist">
        Before starting work you need to get DID
        <a [routerLink]="['/profile']">here</a>
    </div>
</div>

<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>

<mat-menu #schemaMenu="matMenu">
    <ng-template matMenuContent let-element="schema">
        <p-button
            (click)="ifCanExport(element) ? onExport(element) : null"
            [disabled]="!ifCanExport(element)"
            label="Export schema"
            class="schema-menu-p-btn"
            styleClass="p-button-outlined"
            [pTooltip]="'Export schema'"
            tooltipPosition="bottom">
            <svg-icon src="/assets/images/icons/export.svg" 
                [svgClass]="ifCanExport(element) ? 'primary-color' : 'disabled-color'"></svg-icon>
        </p-button>

        <div class="schema-menu-delimiter"></div>

        <!-- <p-button
            (click)="ifCanExport(element) ? exportToExcel(element) : null"
            [disabled]="!ifCanExport(element)"
            label="Export schemas to Excel"
            class="schema-menu-p-btn"
            styleClass="p-button-outlined"
            [pTooltip]="'Export schema to Excel'"
            tooltipPosition="bottom">
            <svg-icon src="/assets/images/icons/export.svg" 
                [svgClass]="ifCanExport(element) ? 'primary-color' : 'disabled-color'"></svg-icon>
        </p-button> -->
        <!-- <p-button
            (click)="canImport ? onImportSchemas(): null"
            [disabled]="!canImport"
            label="Import schemas from Excel"
            class="schema-menu-p-btn"
            styleClass="p-button-outlined"
            [pTooltip]="'Import schemas from excel'"
            tooltipPosition="bottom">
            <svg-icon src="/assets/images/icons/import.svg" svgClass="primary-color"></svg-icon>
        </p-button> -->
        
        <!-- <p-button
            (click)="downloadExcelExample()"
            label="Download Excel example"
            class="schema-menu-p-btn"
            styleClass="p-button-outlined"
            [pTooltip]="'Download Excel Template'"
            tooltipPosition="bottom">
            <svg-icon src="/assets/images/icons/document.svg" svgClass="primary-color"></svg-icon>
        </p-button> -->

        <!-- <div class="schema-menu-delimiter"></div> -->

        <button *ngIf="ifCanCopy(element)" mat-menu-item (click)="onCopySchema(element)" class="schema-menu-btn">
            <mat-icon>content_copy</mat-icon>
            <span>Copy</span>
        </button>

        <button mat-menu-item (click)="onOpenConfig(element)" class="schema-menu-btn">
            <mat-icon>settings</mat-icon>
            <span>Configuration</span>
        </button>

        <button mat-menu-item (click)="onOpenForm(element, true)" class="schema-menu-btn">
            <mat-icon>description</mat-icon>
            <span>Example</span>
        </button>

        <button mat-menu-item (click)="onOpenForm(element, false)" class="schema-menu-btn">
            <mat-icon>visibility</mat-icon>
            <span>Preview</span>
        </button>

        <button mat-menu-item (click)="onOpenDocument(element)" class="schema-menu-btn">
            <mat-icon>segment</mat-icon>
            <span>Schema</span>
        </button>

        <button mat-menu-item (click)="onViewSchemaTree(element)" class="schema-menu-btn">
            <mat-icon>account_tree</mat-icon>
            <span>Tree</span>
        </button>

        <div class="schema-menu-delimiter"></div>

        <p-button
            (click)="ifCanDelete(element) ? onCheckDeleteSchema(element) : null"
            [disabled]="!ifCanDelete(element)"
            label="Delete schema"
            class="schema-menu-p-btn"
            styleClass="p-button-outlined accent-color-red"
            [pTooltip]="'Delete schema'"
            tooltipPosition="bottom">
            <svg-icon
                src="/assets/images/icons/delete.svg"
                [svgClass]="ifCanDelete(element) ? 'accent-color-red' : 'disabled-color'"></svg-icon>
        </p-button>
    </ng-template>
</mat-menu>
