<div *ngIf="type == 'selector' && visible" class="content">
    <ng-container *ngFor="let option of options" >
        <div *ngIf="option.name" class="btn-option {{option.uiClass}}" (click)="onSelect(option.value)">
            {{option.name}}
        </div>
    </ng-container>
</div>

<div *ngIf="type == 'download'" class="content">
    <div class="btn-link" (click)="onDownload()">{{content}}</div>
</div>

<div *ngIf="type == 'dropdown'" class="content-dropdown">
    <mat-select [(value)]="currentValue" (selectionChange)="onDropdown()">
        <mat-option *ngFor="let item of options" [value]="item.value">{{item.name}}</mat-option>
    </mat-select>
</div>

<ng-template #preloader>
    <div class="preloader-image"></div>
</ng-template>

<div *ngIf="loading" class="loading">
    <div class="preloader-image preloader-image-l-size"></div>
</div>
