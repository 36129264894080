<div class="g-dialog-content">
    <div class="g-dialog-body enum-input-container">
        <div class="enum-import-container">
            <mat-form-field class="enum-import-url" appearance="outline">
                <mat-label>URL</mat-label>
                <input
                        [formControl]="urlControl"
                        matInput
                        placeholder="example.com"
                />
            </mat-form-field>
            <button
                    (click)="onImportByUrl()"
                    [disabled]="urlControl.value && !urlControl.valid"
                    class="enum-import-button"
                    color="primary"
                    mat-raised-button
            >
                Import by URL
            </button>
            <button
                    (click)="onImportByFile()"
                    class="enum-import-button"
                    color="primary"
                    mat-raised-button
            >
                Import by file
            </button>
        </div>
        <div class="enum-label">Enum</div>
        <mat-checkbox
                *ngIf="loadToIpfs"
                [(ngModel)]="loadToIpfsValue"
                class="enum-load-to-ipfs"
                color="primary"
                labelPosition="before"
        >
            Will data be loaded to IPFS? (There are more than 5 options)
        </mat-checkbox>
        <div id="text-editor" class="text-editor" *ngIf="!loading">
            <ngx-codemirror
                    (ngModelChange)="checkLoadIpfsVisible($event)"
                    *ngIf="initDialog"
                    [(ngModel)]="enumValue"
                    [options]="codeMirrorOptions"
            ></ngx-codemirror>
        </div>
        <mat-spinner *ngIf="loading" class="loading"></mat-spinner>
    </div>
    <div class="g-dialog-actions">
        <div style="margin-left: auto; width: fit-content">
            <p-button
                    (click)="this.ref.close({enumValue,loadToIpfs:loadToIpfs&&loadToIpfsValue})"
                    class="g-dialog-actions-btn"
                    label="Save"
            ></p-button>
        </div>
    </div>
</div>
